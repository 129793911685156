import React from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import UserProfile from "../../../components/UserProfile"
import { TAfindUser, TAfindUserById, TASearchByUsername } from "../../../services/userAPI"
import { setPageTitle } from "../../../redux/store/themeConfigSlice"
import { User } from "../../../types/UserData"

const FindUser = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const response = await TAfindUserById(id)
        setProfileData(response)
      }
      fetchData()
    }
  }, [id])

  useEffect(() => {
    dispatch(setPageTitle("Kullanıcı Bul"))
  })
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [_id, setId] = useState("")
  const [instaUsername, setInstaUsername] = useState("")
  const [tiktokUsername, setTiktokUsername] = useState("")
  const [profileData, setProfileData] = useState<User | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [instaAutoFill, setInstaAutoFill] = useState<string[]>([])
  const [tiktokAutoFill, setTikTokAutoFill] = useState<string[]>([])

  const handleForm = async (e: any) => {
    e.preventDefault()
    const isNull = (array: any[]) => {
      for (let i = 0; i < array.length; i++) {
        if (array[i] !== "") {
          return false
        }
      }
      return true
    }

    let data
    if (_id !== "" && isNull([email, phone, instaUsername, tiktokUsername])) {
      data = `_id=${_id}`
    } else if (email !== "" && isNull([phone, instaUsername, tiktokUsername, _id])) {
      data = `email=${email}`
    } else if (phone !== "" && isNull([email, instaUsername, tiktokUsername, _id])) {
      const encodedPhone = encodeURIComponent(phone)
      data = `phone=${encodedPhone}`
    } else if (instaUsername !== "" && isNull([email, phone, _id])) {
      data = `insta.username=${instaUsername}`
    } else if (tiktokUsername !== "" && isNull([email, phone, _id])) {
      data = `tiktok.username=${tiktokUsername}`
    } else if (isNull([email, phone, instaUsername, tiktokUsername, _id])) {
      setError("Please provide any criteria")
      return
    } else {
      setError("Please provide only email or phone")
      return
    }

    try {
      const response = await TAfindUser(data)
      if (response) {
        setProfileData(response)
      }
    } catch (error: any) {
      if (error.response) {
        setError(error.response.error)
      } else {
        setError(error.message)
      }
    }
  }

  useEffect(() => {
    const autofillUsername = async () => {
      const response = await TASearchByUsername(instaUsername, "instagram")
      setInstaAutoFill(response)
    }
    if (instaUsername.length > 1) {
      autofillUsername()
    }
  }, [instaUsername])

  useEffect(() => {
    const autofillUsername = async () => {
      const response = await TASearchByUsername(tiktokUsername, "tiktok")
      setTikTokAutoFill(response)
    }
    if (tiktokUsername.length > 1) {
      autofillUsername()
    }
  }, [tiktokUsername])

  const handleSuggestionClick = (selectedUsername: any, setUsername: any, setAutoFıll: any) => {
    setUsername(selectedUsername)
    setAutoFıll([])
  }

  return (
    <div className="flex flex-col lg:flex-row justify-between items-start min-h-screen bg-cover bg-center relative">
      <div className="w-full">{profileData && <UserProfile {...profileData} />}</div>
      <form className="w-1/4 absolute right-6 ">
        <h1>Kullanıcı Bul</h1>
        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">Mail Adresi</h2>
        <input
          type="email"
          placeholder="email@mail.com"
          className="form-input text-sm"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />

        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">IG Kullanıcı Adı</h2>
        <input
          type="instaUsername"
          placeholder="IG Kullanıcı Adı"
          className="form-input text-sm"
          value={instaUsername}
          onChange={(e) => {
            setInstaUsername(e.target.value)
          }}
        />

        {instaAutoFill.length > 0 && instaUsername !== "" && (
          <ul className="suggestion-list" style={{ position: "absolute", zIndex: 9999 }}>
            {[...new Set(instaAutoFill)].slice(0, 5).map((autofillUsername, index) => (
              <li
                key={index}
                className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                onClick={() =>
                  handleSuggestionClick(autofillUsername, setInstaUsername, setInstaAutoFill)
                }
              >
                {autofillUsername}
              </li>
            ))}
          </ul>
        )}

        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">TT Kullanıcı Adı</h2>
        <input
          type="tiktokUsername"
          placeholder="TT Kullanıcı Adı"
          className="form-input text-sm"
          value={tiktokUsername}
          onChange={(e) => {
            setTiktokUsername(e.target.value)
          }}
        />

        {tiktokAutoFill.length > 0 && tiktokUsername !== "" && (
          <ul className="suggestion-list" style={{ position: "absolute", zIndex: 9999 }}>
            {[...new Set(tiktokAutoFill)].slice(0, 5).map((autofillUsername, index) => (
              <li
                key={index}
                className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                onClick={() =>
                  handleSuggestionClick(autofillUsername, setTiktokUsername, setTikTokAutoFill)
                }
              >
                {autofillUsername}
              </li>
            ))}
          </ul>
        )}

        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">Telefon Numarası:</h2>
        <input
          type="tel"
          placeholder="+905*********"
          className="form-input text-sm"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value)
          }}
        />
        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">{"Kullanıcı ID'si:"}</h2>
        <input
          type="text"
          placeholder="Kullanıcı ID"
          className="form-input text-sm"
          value={_id}
          onChange={(e) => {
            setId(e.target.value)
          }}
        />
        {error && <p className="text-red-500 text-sm text-center">{error}</p>}
        <div className="flex justify-center">
          <button type="button" onClick={handleForm} className="btn btn-primary mt-3">
            Gönder
          </button>
        </div>
      </form>
    </div>
  )
}

export default FindUser
