import { useDispatch } from "react-redux"
import React, { useEffect, useState } from "react"
import {
  TASpamBrand,
  TAbalanceLoaded,
  TAcampaignCompleted,
  TAcampaignManagementContinues,
  TAdemoCampaignCreated,
  TAdirectedToAgencyBasedWork,
  TAfindAllBrands,
  TAinformationMailSent,
  TAmeetingPlanned,
  TApersonalAccount,
  TAupdateBrandNote,
  TAupdateBrandStage,
} from "../../../services/brandAPI"
import { DataTable, DataTableSortStatus } from "mantine-datatable"
import sortBy from "lodash/sortBy"
import { setPageTitle } from "../../../redux/store/themeConfigSlice"
import { Brand } from "../../../types/BrandData"
import DatePicker from "react-datepicker"
import { columnConfig } from "./components/columns"
import { generateColumn } from "../../User/bannedUsers/components/columns"
import { handleSorting } from "../../User/getAllUsers/components/Functions"

const fetchData = async (page: number, perPage: number, params: any) => {
  try {
    const response = await TAfindAllBrands(page, perPage, params)
    if (response && Array.isArray(response.brands)) {
      const totalLength = response.brands.length
      const totalRecords = response.totalDocuments
      const data = response.brands.map((item: any, index: any) => {
        return {
          id: totalLength - index,
          ...item,
        }
      })
      return { data, totalRecords }
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

const AllBrands = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle("Range Search Table"))
  })
  const [page, setPage] = useState(1)
  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [totalRecords, setTotalRecords] = useState(0)
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [initialRecords, setInitialRecords] = useState<Brand[]>(sortBy({}, "id"))
  const sortStatus: DataTableSortStatus = { columnAccessor: "id", direction: "asc" }
  const [error, setError] = useState<string | null>(null)
  const [sortByColumn, setSortByColumn] = useState("")
  const [sortOrder, setSortOrder] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notes, setNotes] = useState("")
  const [brandNotes, setBrandNotes] = useState([""])
  const [editMode, setEditMode] = useState<EditModeState>({})
  const [editStage, setEditStage] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState<DropdownState>({})
  const [params, setParams] = useState({
    min_created_at: null as Date | null,
    max_created_at: null as Date | null,
    brand: "",
    stage: [""],
    process: {
      meeting_planned: false,
      information_mail_sent: false,
      directed_to_agency_based_work: false,
      demo_campaign_created: false,
      balance_loaded: false,
      campaign_management_continues: false,
      campaign_completed: false,
      personal_account: false,
    },
  })

  type DropdownState = {
    [key: string]: boolean
  }
  interface EditModeState {
    [key: string]: boolean
  }

  useEffect(() => {
    setLoading(true)
    const loadBrands = async () => {
      try {
        const newParams = {
          min_created_at: params.min_created_at ? params.min_created_at.toISOString() : "",
          max_created_at: params.max_created_at ? params.max_created_at.toISOString() : "",
          brand: params.brand,
          stage: params.stage.join(";"),
          meeting_planned: params.process.meeting_planned.toString(),
          information_mail_sent: params.process.information_mail_sent.toString(),
          directed_to_agency_based_work: params.process.directed_to_agency_based_work.toString(),
          demo_campaign_created: params.process.demo_campaign_created.toString(),
          balance_loaded: params.process.balance_loaded.toString(),
          campaign_management_continues: params.process.campaign_management_continues.toString(),
          campaign_completed: params.process.campaign_completed.toString(),
          personal_account: params.process.personal_account.toString(),
        }
        const queryParams = new URLSearchParams(newParams as Record<string, string>).toString()
        const response = await fetchData(page, pageSize, queryParams)
        if (response !== undefined) {
          setInitialRecords(response.data)
          setTotalRecords(response.totalRecords)
          setLoading(false)
        } else {
          setError("No data found")
        }
      } catch (error) {
        setError("Error fetching data")
      }
    }
    loadBrands()
  }, [page, pageSize, params])

  useEffect(() => {
    setPage(1)
  }, [pageSize])

  const renderBrandId = (record: any, index: number) => {
    const itemsPerPage = page * pageSize
    const recordIndex = itemsPerPage + index
    const brandId = recordIndex - pageSize + 1
    return <div>{brandId}</div>
  }

  const handleUpdateNote = async (_id: string, brandNotes: string[]) => {
    try {
      const brand = await TAupdateBrandNote(_id, brandNotes)
      if (brand) {
        alert("Note updated successfully")
        setEditMode((prev) => ({ ...prev, [_id]: false }))
      }
    } catch (error) {
      console.error(error)
      alert("Failed to update the note")
    }
  }

  const handleDeleteNote = (_id: any, index: any) => {
    const newNotes = brandNotes.filter((note, noteIndex) => noteIndex !== index)
    handleUpdateNote(_id, newNotes)
  }

  const toggleEditMode = (_id: string) => {
    setEditMode((prev) => ({ ...prev, [_id]: true }))
  }

  const handleBrandStage = async (_id: any, stages: any[]) => {
    try {
      const brand = await TAupdateBrandStage(_id, stages)
      if (brand) {
        alert("Stage updated successfully")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleMeetingPlanned = async (_id: any, status: any) => {
    try {
      const brand = await TAmeetingPlanned(_id, status)
      if (brand) {
        alert("Meeting planned status updated successfully")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleInformationMailSent = async (_id: any, status: any) => {
    try {
      const brand = await TAinformationMailSent(_id, status)
      if (brand) {
        alert("Information Mail Sent status updated successfully")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDirectedToAgencyBasedWork = async (_id: any, status: any) => {
    try {
      const brand = await TAdirectedToAgencyBasedWork(_id, status)
      if (brand) {
        alert("Directed to Agency Based Work status updated successfully")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleDemoCampaignCreated = async (_id: any, status: any) => {
    try {
      const brand = await TAdemoCampaignCreated(_id, status)
      if (brand) {
        alert("Demo Campaign Created status updated successfully")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleBalanceLoaded = async (_id: any, status: any) => {
    try {
      const brand = await TAbalanceLoaded(_id, status)
      if (brand) {
        alert("Balance Loaded status updated successfully")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleCampaignManagementContinues = async (_id: any, status: any) => {
    try {
      const brand = await TAcampaignManagementContinues(_id, status)
      if (brand) {
        alert("Campaign Management Continues status updated successfully")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleCampaignCompleted = async (_id: any, status: any) => {
    try {
      const brand = await TAcampaignCompleted(_id, status)
      if (brand) {
        alert("Campaign Completed status updated successfully")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handlePersonalAccount = async (_id: any, status: any) => {
    try {
      const brand = await TApersonalAccount(_id, status)
      if (brand) {
        alert("Personal Account status updated successfully")
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleSpamBrand = (_id: any) => {
    TASpamBrand(_id, "true")
  }

  const handleStartDateChange = (date: Date | null) => {
    setParams((prevParams: any) => ({
      ...prevParams,
      min_created_at: date,
    }))
    if (params.max_created_at && date && date > params.max_created_at) {
      setParams((prevParams: any) => ({
        ...prevParams,
        max_created_at: null,
      }))
    }
  }

  const handleEndDateChange = (date: Date | null) => {
    setParams((prevParams: any) => ({
      ...prevParams,
      max_created_at: date,
    }))
  }

  const handleStageRadioChange = (stage: string) => {
    if (stage === "") {
      setParams({ ...params, stage: [""] })
    } else {
      let stages: string[] = []
      if (params.stage.includes("")) {
        stages = [stage]
      } else {
        const index = params.stage.indexOf(stage)
        if (index !== -1) {
          stages = params.stage.filter((s) => s !== stage)
        } else {
          stages = [...params.stage, stage]
        }
      }
      setParams({ ...params, stage: stages })
    }
  }

  const isChecked = (stage: string) => {
    if (stage === "") {
      return params.stage.includes("")
    } else {
      return params.stage.includes(stage)
    }
  }

  const handleBrandProcessRadioChange = (process: string) => {
    switch (process) {
      case "Toplantı planlandı":
        setParams({
          ...params,
          process: { ...params.process, meeting_planned: !params.process.meeting_planned },
        })
        break
      case "Bilgilendirme maili iletildi":
        setParams({
          ...params,
          process: {
            ...params.process,
            information_mail_sent: !params.process.information_mail_sent,
          },
        })
        break
      case "Ajans bazlı çalışmaya yönlendirildi":
        setParams({
          ...params,
          process: {
            ...params.process,
            directed_to_agency_based_work: !params.process.directed_to_agency_based_work,
          },
        })
        break
      case "Demo kampanya oluşturdu":
        setParams({
          ...params,
          process: {
            ...params.process,
            demo_campaign_created: !params.process.demo_campaign_created,
          },
        })
        break
      case "Bakiye yüklemesi yapıldı":
        setParams({
          ...params,
          process: { ...params.process, balance_loaded: !params.process.balance_loaded },
        })
        break
      case "Kampanya yönetim süreci devam ediyor":
        setParams({
          ...params,
          process: {
            ...params.process,
            campaign_management_continues: !params.process.campaign_management_continues,
          },
        })
        break
      case "Kampanya tamamlandı":
        setParams({
          ...params,
          process: { ...params.process, campaign_completed: !params.process.campaign_completed },
        })
        break
      case "Kişisel Hesap":
        setParams({
          ...params,
          process: { ...params.process, personal_account: !params.process.personal_account },
        })
        break
      case "":
        setParams({
          ...params,
          process: {
            ...params.process,
            meeting_planned: false,
            information_mail_sent: false,
            directed_to_agency_based_work: false,
            demo_campaign_created: false,
            balance_loaded: false,
            campaign_management_continues: false,
            campaign_completed: false,
            personal_account: false,
          },
        })
        break
      default:
        break
    }
  }

  const isProcessChecked = (newProcess: string) => {
    switch (newProcess) {
      case "Toplantı planlandı":
        return params.process.meeting_planned
      case "Bilgilendirme maili iletildi":
        return params.process.information_mail_sent
      case "Ajans bazlı çalışmaya yönlendirildi":
        return params.process.directed_to_agency_based_work
      case "Demo kampanya oluşturdu":
        return params.process.demo_campaign_created
      case "Bakiye yüklemesi yapıldı":
        return params.process.balance_loaded
      case "Kampanya yönetim süreci devam ediyor":
        return params.process.campaign_management_continues
      case "Kampanya tamamlandı":
        return params.process.campaign_completed
      case "Kişisel Hesap":
        return params.process.personal_account
      case "":
        return (params.process as any)[newProcess]
      default:
        break
    }
  }

  const handleDropdownClick = (id: string) => {
    setDropdownOpen((prev: any) => ({ ...prev, [id]: !prev[id] }))
  }

  const handleOptionChange = (handler: any, _id: string, value: any) => {
    handler(_id, value)
  }

  const columns = columnConfig({
    handleSpamBrand,
    renderBrandId,
    editMode,
    handleDeleteNote,
    setNotes,
    notes,
    handleUpdateNote,
    toggleEditMode,
    setBrandNotes,
    editStage,
    handleBrandStage,
    setEditStage,
    handleDropdownClick,
    dropdownOpen,
    handleOptionChange,
    handleMeetingPlanned,
    handleInformationMailSent,
    handleDirectedToAgencyBasedWork,
    handleDemoCampaignCreated,
    handleBalanceLoaded,
    handleCampaignManagementContinues,
    handleCampaignCompleted,
    handlePersonalAccount,
  }).map((col) => generateColumn(col.accessor, col.title, col.sortable, col.render))

  return (
    <div className="panel">
      <div className="mb-4.5 flex md:items-center md:flex-row flex-col gap-5">
        {error && (
          <div className="bg-red-200 text-red-800 border border-red-600 p-2 rounded">{error}</div>
        )}
        <div className="flex items-center flex-col items-start mt-4">
          <div className="flex-col">
            <label className="mr-14 mb-4">Oluşturulma Tarihi Aralığı</label>
          </div>
          <div className="mr-4" style={{ zIndex: 9999 }}>
            <DatePicker
              selected={params.min_created_at}
              onChange={handleStartDateChange}
              selectsStart
              startDate={params.min_created_at}
              dateFormat="dd/MM/yyyy"
              endDate={params.max_created_at}
              className="w-full p-2 border border-gray-200 rounded-md mb-4"
              placeholderText="Başlangıç Tarihi (gg/aa/yyyy)"
            />
          </div>
          <div className="mr-4" style={{ zIndex: 999 }}>
            <DatePicker
              selected={params.max_created_at}
              onChange={handleEndDateChange}
              selectsEnd
              startDate={params.min_created_at}
              dateFormat="dd/MM/yyyy"
              endDate={params.max_created_at}
              minDate={params.min_created_at}
              className="w-full p-2 border border-gray-200 rounded-md mb-4"
              placeholderText="Bitiş Tarihi (gg/aa/yyyy)"
            />
          </div>
        </div>
        <div className="md:flex md:flex-col mr-2 ml-2">
          <h2 className="text-sm font-bold mb-2">Aşamalar</h2>
          {[
            "Arandı",
            "Ulaşılamadı",
            "Tekrar Arandı",
            "Ulaşılamadı, bilgilendirme mesajı iletildi",
            "Kişisel hesap, bilgilendirme verilmedi",
            "",
          ].map((label) => (
            <label key={label}>
              <input
                type="checkbox"
                value={label}
                checked={isChecked(label)}
                onChange={() => handleStageRadioChange(label)}
                className="form-checkbox text-pink-600 mr-2"
              />
              {label || "Any"}
            </label>
          ))}
        </div>
        <div className="md:flex md:flex-col mr-2 ml-2">
          <h2 className="text-sm font-bold mb-2">Marka İşlemi</h2>
          {[
            "Toplantı planlandı",
            "Bilgilendirme maili iletildi",
            "Ajans bazlı çalışmaya yönlendirildi",
            "Demo kampanya oluşturdu",
            "Bakiye yüklemesi yapıldı",
            "Kampanya yönetim süreci devam ediyor",
            "Kampanya tamamlandı",
            "Kişisel Hesap",
            "",
          ].map((label) => (
            <label key={label}>
              <input
                type="checkbox"
                value={label}
                checked={isProcessChecked(label)}
                onChange={() => handleBrandProcessRadioChange(label)}
                className="form-checkbox text-pink-600 mr-2"
              />
              {label || "Any"}
            </label>
          ))}
        </div>
        <div className="ltr:ml-auto rtl:mr-auto flex">
          <input
            type="text"
            className="form-input w-auto mr-4"
            placeholder="Marka ismi ara"
            value={params.brand}
            onChange={(e) => {
              const text = e.target.value
              setParams((prevParams: any) => ({
                ...prevParams,
                brand: text,
              }))
            }}
          />
        </div>
      </div>
      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={initialRecords}
            columns={columns}
            totalRecords={totalRecords}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={(e) =>
              handleSorting(e.columnAccessor, setSortByColumn, setSortOrder)
            }
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
          />
        )}
      </div>
    </div>
  )
}

export default AllBrands
