import { useDispatch } from "react-redux"
import React, { useEffect, useState } from "react"
import { TAfindBrand, TAfindBrandById, TAfindBrandName } from "../../../services/brandAPI"
import { Brand, MoneyExchangeItemBrand } from "../../../types/BrandData"
import { setPageTitle } from "../../../redux/store/themeConfigSlice"
import BrandProfile from "../../../components/BrandProfile"
import { useParams } from "react-router-dom"

const FindBrand = () => {
  const { id } = useParams<{ id: string }>()

  useEffect(() => {
    dispatch(setPageTitle("Find Brands"))
  })

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const response = await TAfindBrandById(id)
        setbrandData(response)
      }
      fetchData()
    }
  }, [id])

  const dispatch = useDispatch()

  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [brandname, setBrandname] = useState("")
  const [brandData, setbrandData] = useState<Brand | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [autofillNames, setAutofillNames] = useState<string[]>([])

  const handleForm = async (e: any) => {
    e.preventDefault()

    let data

    if (email !== "" && phone === "" && brandname === "") {
      data = `email=${email}`
    } else if (phone !== "" && email === "" && brandname === "") {
      const encodedPhone = encodeURIComponent(phone)
      data = `phone=${encodedPhone}`
    } else if (brandname !== "" && email === "" && phone === "") {
      data = `brand_name=${brandname}`
    } else if (!email && !phone && !brandname) {
      setError("Please provide email, phone or brand name")
      return
    } else {
      setError("Please provide only one of these: email, phone or brand name")
      return
    }

    try {
      const res = await TAfindBrand(data)
      const response = Array.isArray(res) ? res[0] : res

      const object: Brand = {
        _id: response._id,
        balance: response.balance,
        email: response.email,
        brand_name: response.brand_name,
        country: response.country,
        first_name: response.first_name,
        last_name: response.last_name,
        phone: response.phone,
        currency: response.currency,
        language: response.language,
        brand_logo: response.brand_logo,
        created_at: response.created_at,
        job_title: response.job_title,
        billing_address: {
          type: response.billing_address?.type ?? "",
          firm_name: response.billing_address?.firm_name ?? "",
          contactName: response.billing_address?.contactName ?? "",
          id: response.billing_address?.id ?? "",
          city: response.billing_address?.city ?? "",
          country: response.billing_address?.country ?? "",
          address: response.billing_address?.address ?? "",
          zipCode: response.billing_address?.zipCode ?? "",
          country_code: response.billing_address?.country_code ?? "",
          tax_type: response.billing_address?.tax_type ?? "",
          default_payment_id: response.billing_address?.default_payment_id ?? "",
        },
        is_email_verified: response.is_email_verified,
        password: response.password,
        brand_category: response.brand_category,
        money_exchanges: Array.isArray(response.money_exchanges)
          ? response.money_exchanges.map((exchange: MoneyExchangeItemBrand) => ({
              operation: exchange?.operation ?? "",
              amount: exchange?.amount ?? 0,
              application_id: exchange?.application_id ?? "",
              action_time: exchange?.action_time ?? "",
            }))
          : [],
        notes: response.notes ? response.notes : "",
        meetings: response.meetings ? response.meetings : "",
        stages: response.stages ? response.stages : "",
        meeting_planned: response.meeting_planned,
        information_mail_sent: response.information_mail_sent,
        directed_to_agency_based_work: response.directed_to_agency_based_work,
        demo_campaign_created: response.demo_campaign_created,
        balance_loaded: response.balance_loaded,
        campaign_management_continues: response.campaign_management_continues,
        campaign_completed: response.campaign_completed,
      }
      setbrandData(object)
    } catch (error: any) {
      setError(error.message)
    }
  }

  useEffect(() => {
    const autofillBrand = async () => {
      const response = await TAfindBrandName(brandname)
      setAutofillNames(response)
    }
    if (brandname.length > 1) {
      autofillBrand()
    }
  }, [brandname])

  const handleSuggestionClick = (selectedBrandname: any) => {
    setBrandname(selectedBrandname)
    setAutofillNames([])
  }

  return (
    <div className="flex flex-col lg:flex-row justify-between items-start min-h-screen bg-cover bg-center relative">
      <div className="w-full ">{brandData && <BrandProfile {...brandData} />}</div>
      <form className="w-1/4 absolute top-5 right-6">
        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">Marka Maili</h2>
        <input
          type="email"
          placeholder="email@mail.com"
          className="form-input text-sm"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
          }}
        />

        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">Marka İsmi</h2>
        <input
          type="brandname"
          placeholder="marka ismi"
          className="form-input text-sm"
          value={brandname}
          onChange={(e) => {
            setBrandname(e.target.value)
          }}
        />

        {autofillNames.length > 0 && (
          <ul className="suggestion-list" style={{ position: "absolute", zIndex: 9999 }}>
            {[...new Set(autofillNames)].slice(0, 5).map((autofillName, index) => (
              <li
                key={index}
                className="bg-white p-2 text-black cursor-pointer hover:bg-gray-200"
                onClick={() => handleSuggestionClick(autofillName)}
              >
                {autofillName}
              </li>
            ))}
          </ul>
        )}

        <h2 className="text-sm font-bold mb-1 mt-3 ml-2">Marka Telefon Numarası</h2>
        <input
          type="tel"
          placeholder="+905554443322"
          className="form-input text-sm"
          onChange={(e) => {
            setPhone(e.target.value)
          }}
        />
        {error && <p className="text-red-500 text-sm text-center">{error}</p>}
        <div className="flex justify-center">
          <button type="button" onClick={handleForm} className="btn btn-primary mt-3">
            Gönder
          </button>
        </div>
      </form>
    </div>
  )
}

export default FindBrand
