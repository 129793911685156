import React from "react"

interface RadioInputProps {
  placeholder: string
  value: string
  onChange: (value: string) => void
}

const DateField: React.FC<RadioInputProps> = ({ placeholder, value, onChange }) => {
  return (
    <div className="md:flex md:flex-col mr-2 ml-2">
      <h2 className="text-sm font-bold mb-2">{placeholder}</h2>
      {["1 Ay", "3 Ay", "6 Ay", "1 Yıl", "any"].map((label, index) => {
        const monthsAgo = [1, 3, 6, 12]
        const date = new Date(
          new Date().setMonth(new Date().getMonth() - monthsAgo[index]),
        ).toLocaleDateString("en-CA")

        return (
          <label key={label}>
            <input
              type="radio"
              value={date}
              checked={value === date}
              onChange={(e) => onChange(e.target.value)}
              className="form-radio text-pink-600 mr-2"
            />
            {label}
          </label>
        )
      })}
    </div>
  )
}

export default DateField
