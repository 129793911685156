import React from "react"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { DataTable } from "mantine-datatable"
import sortBy from "lodash/sortBy"
import { setPageTitle } from "../../../redux/store/themeConfigSlice"
import { TAwpMessages } from "../../../services/statisticsAPI"

const phoneNumberFixer = (phoneNumber: string) => {
  return phoneNumber.replace("whatsapp:", "")
}

const profileNamefixer = (profileName: string) => {
  if (profileName.length > 25) {
    return profileName.slice(0, 25) + "..."
  } else {
    return profileName
  }
}

const fetchData = async (page: number, perPage: number) => {
  try {
    const response = await TAwpMessages(page, perPage)
    if (response && Array.isArray(response.messages)) {
      const totalPages = response.totalPages
      const data = response.messages.map((item: any, index: number) => {
        return {
          id: index + 1,
          _id: item._id,
          profileName: profileNamefixer(item.profileName),
          from: phoneNumberFixer(item.from),
          ...item,
        }
      })
      return { data, totalPages }
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

const WpMessages = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPageTitle("Range Search Table"))
  }, [dispatch])

  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [wpData, setWpData] = useState([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [totalPages, setTotalPages] = useState(0)
  const [initialRecords, setInitialRecords] = useState(sortBy(wpData, "id"))
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const handleFetchData = async () => {
    setLoading(true)

    try {
      const data: any = await fetchData(page, pageSize)
      if (data !== undefined) {
        setWpData(data.data)
        setInitialRecords(data.data)
        setTotalPages(data.totalPages)
      } else {
        setError("No data found")
      }
      setLoading(false)
    } catch (error) {
      setError("No data found")
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [page, pageSize])

  return (
    <div className="panel">
      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={initialRecords}
            columns={[
              { accessor: "profileName", title: "Profil İsmi", sortable: true },
              { accessor: "from", title: "Telefon", sortable: true },
              { accessor: "messageType", title: "Mesaj Türü", sortable: true },
              { accessor: "body", title: "İçerik", sortable: true },
            ]}
            totalRecords={totalPages * pageSize}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
          />
        )}
      </div>
    </div>
  )
}

export default WpMessages
