import { createSlice } from "@reduxjs/toolkit"

export const bannedUserFiltersSlice = createSlice({
  name: "bannedUserFilters",
  initialState: {
    filters: {
      age: { min: "", max: "" },
      followers: { min: "", max: "" },
      average_like: { min: "", max: "" },
      engagement_rate: { min: "", max: "" },
      average_reel_view: { min: "", max: "" },
      tiktok_followers: { min: "", max: "" },
      tiktok_average_like: { min: "", max: "" },
      tiktok_engagement_rate: { min: "", max: "" },
      country: "",
      city: "",
      job: "",
      hobbies: [],
      keywords: [],
      frequency: "",
      gender: "",
      verification: "",
      platform: "",
      is_spam: "",
      unvisible: "",
      warnings: "",
      insta_username: "",
      tiktok_username: "",
    },
  },
  reducers: {
    setBannedUserFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { setBannedUserFilters } = bannedUserFiltersSlice.actions

export const selectBannedUserFilters = (state: any) => state.bannedUserFilters.filters

export default bannedUserFiltersSlice.reducer
