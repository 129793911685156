import { createSlice } from "@reduxjs/toolkit"

export const doApprovalUserFilterSlice = createSlice({
  name: "doApprovalUserFilters",
  initialState: {
    filters: {
      age: { min: "", max: "" },
      followers: { min: "", max: "" },
      average_like: { min: "", max: "" },
      engagement_rate: { min: "", max: "" },
      average_reel_view: { min: "", max: "" },
      tiktok_followers: { min: "", max: "" },
      tiktok_average_like: { min: "", max: "" },
      tiktok_engagement_rate: { min: "", max: "" },
      country: "",
      city: "",
      job: "",
      hobbies: [],
      keywords: [],
      frequency: "",
      gender: "",
      verification: "",
      platform: "",
      is_spam: "",
      unvisible: "",
      warnings: "",
      insta_username: "",
      tiktok_username: "",
    },
  },
  reducers: {
    setDoApprovalUserFilters: (state, action) => {
      state.filters = action.payload
    },
  },
})

export const { setDoApprovalUserFilters } = doApprovalUserFilterSlice.actions

export const selectDoApprovalUserFilters = (state: any) => state.doApprovalUserFilters.filters

export default doApprovalUserFilterSlice.reducer
