import apiClient from "./axiosInstance"

export const TAstatistics = async () => {
  const response = await apiClient.get("/admin/statistics/statisticsnum", {
    timeout: 60000,
  })

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Statistics not found")
  }
}

export const TAtcNoControl = async (
  tcNo: string,
  firstName: string,
  lastName: string,
  birthYear: string,
) => {
  const response = await apiClient.post(
    `/admin/statistics/tcnocontrol?tcNo=${tcNo}&firstName=${firstName}&lastName=${lastName}&birthYear=${birthYear}`,
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Statistics not found")
  }
}

export const TargetPopularBrands = async (perPage: number, page: number) => {
  const response = await apiClient.get(
    `/admin/brand/getpopularbrands?perPage=${perPage}&page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Update failed")
  }
}

export const TAwpMessages = async (page: number, perPage: number) => {
  const response = await apiClient.get(
    `/admin/statistics/wp-messages?page=${page}&perPage=${perPage}`,
    {
      timeout: 60000,
    },
  )

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error("Statistics not found")
  }
}
