import React, { useState } from "react"
import Modal from "../../../../components/Modal"
import MinMaxInput from "../../getAllUsers/components/MinMaxInput"
import {
  setDoApprovalUserFilters,
  selectDoApprovalUserFilters,
} from "../../../../redux/store/filters/user/doApprovalUserFilterSlice"
import { handleKeywordInputChange } from "../../getAllUsers/components/Functions"
import { useDispatch, useSelector } from "react-redux"
import TextInput from "../../getAllUsers/components/TextInput"
import HobbieSelection from "../../getAllUsers/components/HobbySelection"
import RadioInput from "../../getAllUsers/components/RadioInput"
import DateField from "../../getAllUsers/components/DateField"

interface FiltersProps {
  isOpen: boolean
  onClose: () => void
  handleFetchData: () => void
}

const Filters: React.FC<FiltersProps> = ({ isOpen, onClose, handleFetchData }) => {
  const dispatch = useDispatch()
  const filters = useSelector(selectDoApprovalUserFilters)
  const [keywords, setKeywords] = useState("")
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleRedux = (key: any, value: any) => {
    dispatch(setDoApprovalUserFilters({ ...filters, [key]: value }))
  }

  const toggleHobbySelection = (hobbyName: string) => {
    if (filters?.hobbies.includes(hobbyName)) {
      dispatch(
        setDoApprovalUserFilters({
          ...filters,
          hobbies: filters?.hobbies.filter((hobby: string) => hobby !== hobbyName),
        }),
      )
    } else {
      dispatch(
        setDoApprovalUserFilters({ ...filters, hobbies: [...(filters?.hobbies || []), hobbyName] }),
      )
    }
  }
  const isSelected = (hobbyName: string) => filters?.hobbies.includes(hobbyName)

  const handleClear = () => {
    dispatch(
      setDoApprovalUserFilters({
        age: { min: "", max: "" },
        followers: { min: "", max: "" },
        average_like: { min: "", max: "" },
        engagement_rate: { min: "", max: "" },
        average_reel_view: { min: "", max: "" },
        tiktok_followers: { min: "", max: "" },
        tiktok_average_like: { min: "", max: "" },
        tiktok_engagement_rate: { min: "", max: "" },
        country: "",
        city: "",
        job: "",
        hobbies: [],
        keywords: [],
        frequency: "",
        gender: "",
        verification: "",
        platform: "",
        is_spam: "",
        unvisible: "",
        warnings: "",
        insta_username: "",
        tiktok_username: "",
      }),
    )
  }

  return (
    <>
      {isOpen && (
        <div className="flex w-full items-center justify-center">
          <Modal
            classname="fixed inset-0 bg-gray-800 bg-opacity-50 p-3 flex items-center justify-end z-50"
            classname2="relative bg-white rounded-3xl w-full md:w-1/2 h-full"
            onClick={onClose}
          >
            <div className="w-full p-4 h-full overflow-y-auto">
              <div className="w-full">
                <h2 className="font-semibold text-2xl">Filtrele</h2>
                <div className="border"></div>
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-4">
                <MinMaxInput
                  value={filters.age.min}
                  setValue={(e) => {
                    handleRedux("age", { ...filters.age, min: e.target.value })
                  }}
                  placeholder="Min. Yaş"
                />
                <MinMaxInput
                  value={filters.age.max}
                  setValue={(e) => {
                    handleRedux("age", { ...filters.age, max: e.target.value })
                  }}
                  placeholder="Max. Yaş"
                />
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <MinMaxInput
                  value={filters.followers.min}
                  setValue={(e) => {
                    handleRedux("followers", { ...filters.followers, min: e.target.value })
                  }}
                  placeholder="Min. Instagram Takipçi"
                />
                <MinMaxInput
                  value={filters.followers.max}
                  setValue={(e) => {
                    handleRedux("followers", { ...filters.followers, max: e.target.value })
                  }}
                  placeholder="Max. Instagram Takipçi"
                />
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <MinMaxInput
                  value={filters.average_like.min}
                  setValue={(e) => {
                    handleRedux("average_like", { ...filters.average_like, min: e.target.value })
                  }}
                  placeholder="Min. Instagram Ortalama Beğeni"
                />
                <MinMaxInput
                  value={filters.average_like.max}
                  setValue={(e) => {
                    handleRedux("average_like", { ...filters.average_like, max: e.target.value })
                  }}
                  placeholder="Max. Instagram Ortalama Beğeni"
                />
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <MinMaxInput
                  value={filters.average_reel_view.min}
                  setValue={(e) => {
                    handleRedux("average_reel_view", {
                      ...filters.average_reel_view,
                      min: e.target.value,
                    })
                  }}
                  placeholder="Min. Instagram Ortalama Reel İzlenme"
                />
                <MinMaxInput
                  value={filters.average_reel_view.max}
                  setValue={(e) => {
                    handleRedux("average_reel_view", {
                      ...filters.average_reel_view,
                      max: e.target.value,
                    })
                  }}
                  placeholder="Max. Instagram Ortalama Reel İzlenme"
                />
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <MinMaxInput
                  value={filters.engagement_rate.min}
                  setValue={(e) => {
                    handleRedux("engagement_rate", {
                      ...filters.engagement_rate,
                      min: e.target.value,
                    })
                  }}
                  placeholder="Min. Instagram Etkileşim Oranı"
                />
                <MinMaxInput
                  value={filters.engagement_rate.max}
                  setValue={(e) => {
                    handleRedux("engagement_rate", {
                      ...filters.engagement_rate,
                      max: e.target.value,
                    })
                  }}
                  placeholder="Max. Instagram Etkileşim Oranı"
                />
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <MinMaxInput
                  value={filters.tiktok_followers.min}
                  setValue={(e) => {
                    handleRedux("tiktok_followers", {
                      ...filters.tiktok_followers,
                      min: e.target.value,
                    })
                  }}
                  placeholder="Min. TikTok Takipçi"
                />
                <MinMaxInput
                  value={filters.tiktok_followers.max}
                  setValue={(e) => {
                    handleRedux("tiktok_followers", {
                      ...filters.tiktok_followers,
                      max: e.target.value,
                    })
                  }}
                  placeholder="Max. TikTok Takipçi"
                />
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <MinMaxInput
                  value={filters.tiktok_average_like.min}
                  setValue={(e) => {
                    handleRedux("tiktok_average_like", {
                      ...filters.tiktok_average_like,
                      min: e.target.value,
                    })
                  }}
                  placeholder="Min. TikTok Ortalama Beğeni"
                />
                <MinMaxInput
                  value={filters.tiktok_average_like.max}
                  setValue={(e) => {
                    handleRedux("tiktok_average_like", {
                      ...filters.tiktok_average_like,
                      max: e.target.value,
                    })
                  }}
                  placeholder="Max. TikTok Ortalama Beğeni"
                />
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <MinMaxInput
                  value={filters.tiktok_engagement_rate.min}
                  setValue={(e) => {
                    handleRedux("tiktok_engagement_rate", {
                      ...filters.tiktok_engagement_rate,
                      min: e.target.value,
                    })
                  }}
                  placeholder="Min. TikTok Etkileşim Oranı"
                />
                <MinMaxInput
                  value={filters.tiktok_engagement_rate.max}
                  setValue={(e) => {
                    handleRedux("tiktok_engagement_rate", {
                      ...filters.tiktok_engagement_rate,
                      max: e.target.value,
                    })
                  }}
                  placeholder="Max. TikTok Etkileşim Oranı"
                />
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <TextInput
                  value={filters.country}
                  setValue={(e) => {
                    handleRedux("country", e)
                  }}
                  placeholder="Ülke"
                />
                <TextInput
                  value={filters.city}
                  setValue={(e) => {
                    handleRedux("city", e)
                  }}
                  placeholder="Şehir"
                />
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <TextInput
                  value={filters.job}
                  setValue={(e) => {
                    handleRedux("job", e)
                  }}
                  placeholder="Meslek"
                />
                <TextInput
                  value={filters.frequency}
                  setValue={(e) => {
                    handleRedux("frequency", e)
                  }}
                  placeholder="Sıklık"
                />
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <TextInput
                  value={filters.keywords}
                  setValue={(inputValue) => {
                    const keywords = inputValue.split(",").map((word) => {
                      const trimmedWord = word.trim()
                      return (
                        trimmedWord.charAt(0).toUpperCase() + trimmedWord.slice(1).toLowerCase()
                      )
                    })
                    handleRedux("keywords", keywords)
                    handleKeywordInputChange(inputValue, setKeywords, setIsDropdownOpen)
                  }}
                  placeholder="Nesneler"
                />
              </div>
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <HobbieSelection
                  isSelected={isSelected}
                  toggleHobbySelection={toggleHobbySelection}
                />
              </div>
              {/* <div className="flex w-full justify-between items-center gap-4 mt-3">
                <TextInput
                  value={filters.insta_username}
                  setValue={(e) => {
                    handleRedux("insta_username", e)
                  }}
                  placeholder="Instagram Kullanıcı Adı"
                />
                <TextInput
                  value={filters.tiktok_username}
                  setValue={(e) => {
                    handleRedux("tiktok_username", e)
                  }}
                  placeholder="TikTok Kullanıcı Adı"
                />
              </div> */}
              <div className="flex w-full justify-between items-center gap-4 mt-3">
                <RadioInput
                  placeholder="Cinsiyet"
                  value={filters.gender}
                  onChange={(e) => handleRedux("gender", e)}
                  option1="male"
                  option2="female"
                  label1="Erkek"
                  label2="Kadın"
                />
                <RadioInput
                  placeholder="Onay Durumu"
                  value={filters.verification}
                  onChange={(e) => handleRedux("verification", e)}
                  option1="true"
                  option2="false"
                  label1="Onaylı"
                  label2="Onaysız"
                />
                <RadioInput
                  placeholder="Platform"
                  value={filters.platform}
                  onChange={(e) => handleRedux("platform", e)}
                  option1="insta"
                  option2="tiktok"
                  label1="Instagram"
                  label2="TikTok"
                />
                <RadioInput
                  placeholder="Spam Durumu"
                  value={filters.is_spam}
                  onChange={(e) => handleRedux("is_spam", e)}
                  option1="true"
                  option2="false"
                  label1="Spam"
                  label2="Spam Değil"
                />
                <RadioInput
                  placeholder="Görünürlük"
                  value={filters.unvisible}
                  onChange={(e) => handleRedux("unvisible", e)}
                  option1="true"
                  option2="false"
                  label1="Görünür"
                  label2="Görünmez"
                />
                <RadioInput
                  placeholder="Uyarılar"
                  value={filters.warnings}
                  onChange={(e) => handleRedux("warnings", e)}
                  option1="true"
                  option2="false"
                  label1="Var"
                  label2="Yok"
                />
                <DateField
                  placeholder="Son Giriş Tarihi"
                  value={filters.last_login}
                  onChange={(e) => handleRedux("last_login", e)}
                />
              </div>

              <div
                className="flex flex-row justify-between w-full mt-24 py-4 space-x-24"
                style={{
                  boxShadow: "0px -12px 20px -10px rgba(0, 0, 128, 0.1)",
                }}
              >
                <button className="text-blue h-12 rounded-full" onClick={handleClear}>
                  Temizle
                </button>
                <button
                  className="text-blue bg-blue-100 h-12 px-4 rounded-full"
                  onClick={() => {
                    handleFetchData()
                    onClose()
                  }}
                >
                  <span>Uygula</span>
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  )
}

export default Filters
