import React from "react"

interface StatisticCardProps {
  title: string
  value: number
}

const StatisticCard: React.FC<StatisticCardProps> = ({ title, value }) => {
  return (
    <div className="flex flex-col panel bg-indigo-100">
      <div className="flex">
        <div className="ltr:mr-1 rtl:ml-1 text-md font-bold text-indigo-600">{title}</div>
      </div>
      <div className="flex justify-center mt-7">
        <div className="text-3xl font-bold ltr:mr-3 rtl:ml-3 text-indigo-600">{value}</div>
      </div>
    </div>
  )
}

export default StatisticCard
