import React, { useState, useRef } from "react"
import ClearIcon from "../assets/images/icons/clear.svg"
import Modal from "../components/Modal"
import northEast from "../assets/images/icons/north_east.svg"
import favorite from "../assets/images/icons/favorite.svg"
import comment from "../assets/images/icons/mode_comment.png"
import instagramLogo from "../assets/images/icons/Instagram.svg"
import tiktokLogo from "../assets/images/icons/TikTok.svg"
import { MapPinIcon } from "@heroicons/react/20/solid"
import { HobbiesGenerator } from "../utils/HobbiesGenerator"
import VideoPlayer from "./VideoPlayer"
// import { IoMdCheckmark } from "react-icons/io"
// import { IoCloseSharp } from "react-icons/io5"

interface ProfileDetailModal {
  onClose?: () => void
  isOpen?: boolean
  data: any
}
const ProfileDetailModal: React.FC<ProfileDetailModal> = ({ isOpen, onClose, data }) => {
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e: React.MouseEvent) => {
    setIsDragging(true)
    setStartX(e.pageX - (scrollContainerRef.current?.offsetLeft || 0))
    setScrollLeft(scrollContainerRef.current?.scrollLeft || 0)
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) {
      return
    }
    e.preventDefault()
    const x = e.pageX - (scrollContainerRef.current?.offsetLeft || 0)
    const walk = (x - startX) * 1
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft = scrollLeft - walk
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  return (
    <>
      {isOpen && (
        <div className="flex  w-full items-center justify-center">
          <Modal
            classname="fixed inset-0 bg-gray-800 bg-opacity-50 p-3 flex items-center justify-end z-50"
            classname2="relative bg-white rounded-3xl w-full md:w-1/2 h-full"
            onClick={onClose}
          >
            <div className="flex flex-col justify-between w-full h-full items-start p-8 overflow-auto">
              <div className="w-full space-y-2">
                <div className="flex flex-row w-full justify-between items-center mb-4 py-1 border-b border-neutral-100">
                  <h2 className="text-lg font-bold text-center">@{data?.insta.username}</h2>
                  <img
                    src={ClearIcon}
                    alt="Close"
                    className="w-6 h-6 p-1 cursor-pointer bg-gray-500 bg-opacity-10 rounded-lg"
                    onClick={onClose}
                  />
                </div>
                <div className="flex flex-row items-start justify-start gap-x-4">
                  <img
                    alt="profile_pic"
                    src={data?.insta.profile_pic}
                    className="h-36 w-36 rounded-md"
                  />
                  <div>
                    <div className="flex flex-row items-center gap-x-2">
                      <p className="font-poppins-medium text-sm text-black">
                        @{data?.insta.username}
                      </p>
                      <a
                        href={`https://www.instagram.com/${data?.insta.username}`}
                        target="_blank"
                        rel="noreferrer"
                        className="flex flex-row gap-x-2 items-center cursor-pointer"
                      >
                        <img alt="brand_logo" className="h-4 w-4 shrink-0" src={instagramLogo} />{" "}
                        <p className="font-poppins-medium text-xs text-primaryColors-rose">
                          Profile Git
                        </p>{" "}
                        <img alt="nortEast" className="h-4 w-4 shrink-0" src={northEast} />
                      </a>
                    </div>
                    <p className="font-poppins-regular text-xs text-[#5A5A62]">
                      {data?.insta.full_name}
                    </p>
                    <div className="flex flex-row gap-x-1 items-center ">
                      <MapPinIcon className="h-4 w-4 shrink-0 text-[#5A5A62]" />
                      <p className="font-poppins-medium text-sm text-black">{data?.city}</p>
                    </div>
                    <div className="flex flex-col mt-2 gap-y-2">
                      <div className="flex flex-row items-center gap-x-2">
                        <p className="font-poppins-regular text-xs text-[#71717A]">Hobiler</p>
                        <div className="flex flex-row flex-wrap gap-x-2 gap-y-2">
                          {HobbiesGenerator({ data: data?.hobbies }).map(
                            (item: string, index: number) => (
                              <div
                                key={index}
                                className="py-1 px-2 bg-neutral-50 rounded-2xl items-center justify-center"
                              >
                                <p className="font-poppins-medium text-[10px] text-black">{item}</p>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                      <div className="flex flex-row items-center gap-x-2">
                        <p className="font-poppins-regular text-xs text-[#71717A]">Yaş</p>
                        <p className="font-poppins-semiBold text-sm text-black">
                          {data?.age > 65 ? "65" : data.age}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-between p-2 gap-2 rounded-xl bg-[#F1F1F2] w-full">
                  <div className="flex flex-row items-center justify-start gap-x-2 w-full border-r border-[#E3E3E4]">
                    <img alt="alt_brand_logo" className="h-5 w-5 shrink-0" src={instagramLogo} />
                    <div>
                      <p className="font-poppins-regular text-sm text-[#71717A]">Takipçi</p>
                      <p className="font-poppins-semiBold text-base text-black">
                        {data?.insta.followers?.toLocaleString("tr-TR")}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-x-2 w-full border-r border-[#E3E3E4]">
                    <p className="font-poppins-regular text-sm text-[#71717A]">Paylaşım Sayısı</p>
                    <p className="font-poppins-semiBold text-base text-black">
                      {data?.insta.post_number}
                    </p>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-x-2 w-full border-r border-[#E3E3E4]">
                    <p className="font-poppins-regular text-sm text-[#71717A]">Takip Edilen</p>
                    <p className="font-poppins-semiBold text-base text-black">
                      {data?.insta.following}
                    </p>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-x-2 w-full border-r border-[#E3E3E4]">
                    <p className="font-poppins-regular text-sm text-[#71717A]">Ortalama Beğeni</p>
                    <p className="font-poppins-semiBold text-base text-black">
                      {data?.insta.average_like && data?.insta.average_like.toFixed(1)}
                    </p>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-x-2 w-full">
                    <p className="font-poppins-regular text-sm text-[#71717A]">Etkileşim Puanı</p>
                    <p className="font-poppins-semiBold text-base text-black">
                      {data?.insta.engagement_rate && data?.insta.engagement_rate.toFixed(1)}%
                    </p>
                  </div>
                </div>
                <div
                  className="flex flex-row overflow-x-scroll"
                  ref={scrollContainerRef}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={() => setIsDragging(false)}
                  style={{
                    cursor: isDragging ? "grabbing" : "grab",
                    scrollbarWidth: "none",
                  }}
                >
                  <div className="flex flex-row gap-x-4" style={{ display: "flex" }}>
                    {data?.insta?.shared_posts?.map((item: any, index: any) => (
                      <div
                        key={index}
                        className="h-48 w-32 sm:h-80 sm:w-64 rounded-xl border border-neutral-100 flex flex-col items-center justify-between"
                      >
                        <img
                          src={item?.media_url}
                          alt={`Post ${index}`}
                          className="h-[90%] w-full rounded-t-xl object-contain pointer-events-none"
                        />
                        <div className="flex flex-row gap-x-4 mb-2">
                          <div className="flex flex-row items-center gap-x-2">
                            <img alt="favorite" className="h-4 w-4 shrink-0" src={favorite} />
                            <p className="font-poppins-medium text-sm text-black">
                              {item?.like_count === "-1" ? 0 : item?.like_count}
                            </p>
                          </div>
                          <div className="flex flex-row gap-x-2 items-center">
                            <img
                              alt="comment"
                              className="h-4 w-4 shrink-0 pointer-events-none"
                              src={comment}
                            />
                            <p className="font-poppins-medium text-sm text-black">
                              {item?.comment_count}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row items-center justify-between p-2 gap-2 rounded-xl bg-[#F1F1F2] w-full">
                  <div className="flex flex-row items-center justify-start gap-x-2 w-full border-r border-[#E3E3E4]">
                    <img alt="alt_brand_logo" className="h-5 w-5 shrink-0" src={tiktokLogo} />
                    <div>
                      <p className="font-poppins-regular text-sm text-[#71717A]">Takipçi</p>
                      <p className="font-poppins-semiBold text-base text-black">
                        {data?.tiktok?.followers?.toLocaleString("tr-TR")}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-x-2 w-full border-r border-[#E3E3E4]">
                    <p className="font-poppins-regular text-sm text-[#71717A]">Paylaşım Sayısı</p>
                    <p className="font-poppins-semiBold text-base text-black">
                      {data?.tiktok?.videos?.length}
                    </p>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-x-2 w-full border-r border-[#E3E3E4]">
                    <p className="font-poppins-regular text-sm text-[#71717A]">Takip Edilen</p>
                    <p className="font-poppins-semiBold text-base text-black">
                      {data?.tiktok?.following}
                    </p>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-x-2 w-full border-r border-[#E3E3E4]">
                    <p className="font-poppins-regular text-sm text-[#71717A]">Ortalama Beğeni</p>
                    <p className="font-poppins-semiBold text-base text-black">
                      {data?.tiktok?.tiktok_average_like &&
                        data?.tiktok?.tiktok_average_like.toFixed(1)}
                    </p>
                  </div>
                  <div className="flex flex-col items-start justify-center gap-x-2 w-full">
                    <p className="font-poppins-regular text-sm text-[#71717A]">Etkileşim Puanı</p>
                    <p className="font-poppins-semiBold text-base text-black">
                      {data?.tiktok?.tiktok_engagement_rate &&
                        data?.tiktok?.tiktok_engagement_rate.toFixed(1)}
                      %
                    </p>
                  </div>
                </div>
                <div
                  className="flex flex-row overflow-x-scroll"
                  ref={scrollContainerRef}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={() => setIsDragging(false)}
                  style={{
                    cursor: isDragging ? "grabbing" : "grab",
                    scrollbarWidth: "none",
                  }}
                >
                  <div className="flex flex-row gap-x-4" style={{ display: "flex" }}>
                    {data?.tiktok?.videos?.map((item: any, index: any) => (
                      <div
                        key={index}
                        className="h-48 w-32 sm:h-80 sm:w-64 rounded-xl border border-neutral-100 flex flex-col items-center justify-between"
                        onClick={() => {
                          setSelectedVideo(item.media_url)
                        }}
                      >
                        <img
                          src={item?.cover}
                          alt={`Post ${index}`}
                          className="h-[90%] w-full rounded-t-xl object-cover"
                        />
                        <div className="flex flex-row gap-x-4 mb-2">
                          <div className="flex flex-row items-center gap-x-2">
                            <img
                              alt="tiktok_favorite"
                              className="h-4 w-4 shrink-0 pointer-events-none"
                              src={favorite}
                            />
                            <p className="font-poppins-medium text-sm text-black">
                              {item?.like_count === "-1" ? 0 : item?.like_count}
                            </p>
                          </div>
                          <div className="flex flex-row gap-x-2 items-center">
                            <img
                              alt="tiktok_comment"
                              className="h-4 w-4 shrink-0 pointer-events-none"
                              src={comment}
                            />
                            <p className="font-poppins-medium text-sm text-black">
                              {item?.comment_count}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {selectedVideo && (
                    <VideoPlayer
                      selectedVideo={selectedVideo}
                      onClose={() => setSelectedVideo("")}
                    />
                  )}
                </div>
              </div>
              {/* <div className="flex flex-row w-full space-x-8 mt-2">
                <button
                  className="w-full  py-8 text-black h-12 bg-[#FECACA] rounded-full flex items-center justify-center gap-3"
                  onClick={reject}
                >
                  <IoCloseSharp size={20} /> İptal Et
                </button>

                {window.location.pathname.includes("waiting_content") ? (
                  <SendNotification id={data} from={"detailModal"} />
                ) : (
                  <button
                    className="w-full py-8 text-black h-12 bg-[#BBF7D0] rounded-full flex items-center justify-center gap-3"
                    onClick={accept}
                  >
                    <IoMdCheckmark size={20} /> Onayla
                  </button>
                )}
              </div> */}
            </div>
          </Modal>
        </div>
      )}
    </>
  )
}

export default ProfileDetailModal
