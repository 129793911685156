import { faEye, faMars, faVenus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { numFixer } from "../../getAllUsers/components/Functions"

export const columnConfig = ({ renderBrandId }) => [
  {
    accessor: "details",
    title: "Detaylar",
    sortable: true,
    render: ({ user_info }) => (
      <a href={`/user/find/${user_info._id}`} target="_blank" rel="noopener noreferrer">
        <div className="text-center items-center mr-4">
          <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
        </div>
      </a>
    ),
  },
  { accessor: "id", title: "Id", sortable: true, render: renderBrandId },
  {
    accessor: "user_info.name",
    title: "İsim",
    sortable: true,
  },
  { accessor: "user_info.email", title: "Email", sortable: true },
  { accessor: "user_info.phone", title: "Telefon", sortable: true },
  {
    accessor: "user_info.age",
    title: "Yaş",
    sortable: true,
    render: ({ user_info }) => <div>{user_info.age > 65 ? "65" : user_info.age}</div>,
  },
  { accessor: "user_info.job", title: "Meslek", sortable: true },
  {
    accessor: "user_info.gender",
    title: "Cinsiyet",
    sortable: true,
    render: ({ user_info }) => (
      <div className="text-center items-center">
        {user_info.gender === "male" ? (
          <FontAwesomeIcon icon={faMars} style={{ color: "#005eff" }} />
        ) : (
          <FontAwesomeIcon icon={faVenus} style={{ color: "#ff00dd" }} />
        )}
      </div>
    ),
  },
  { accessor: "user_info.country", title: "Ülke", sortable: true },
  { accessor: "user_info.insta.full_name", title: "IG Tam İsim", sortable: true },
  {
    accessor: "user_info.insta.username",
    title: "Insta Kullanıcı Adı",
    sortable: true,
    render: ({ user_info }: any) => (
      <a
        href={`https://www.instagram.com/${user_info.insta?.username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="text-center items-center">
          <span>{user_info.insta?.username}</span>
        </div>
      </a>
    ),
  },
  {
    accessor: "user_info.tiktok.username",
    title: "TikTok Kullanıcı Adı",
    sortable: true,
    render: ({ user_info }: any) => (
      <a
        href={`https://www.tiktok.com/@${user_info.tiktok?.username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="text-center items-center">
          <span>{user_info.tiktok?.username}</span>
        </div>
      </a>
    ),
  },
  {
    accessor: "user_info.insta.followers",
    title: "Insta Takipçi",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.user_info.insta.followers)}</div>
    },
  },
  {
    accessor: "user_info.insta.average_like",
    title: "Insta Ortalama Beğeni",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.user_info.insta?.average_like)}</div>
    },
  },
  {
    accessor: "user_info.insta.engagement_rate",
    title: "Insta Etkileşim Oranı",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.user_info.insta?.engagement_rate)}</div>
    },
  },
  {
    accessor: "user_info.insta.post_number",
    title: "Insta Gönderi Sayısı",
    sortable: true,
  },
  { accessor: "user_info.tiktok.followers", title: "TikTok Takipçi", sortable: true },
  {
    accessor: "user_info.tiktok.tiktok_average_like",
    title: "TikTok Ortalama Beğeni",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.user_info.tiktok?.tiktok_average_like)}</div>
    },
  },
  {
    accessor: "user_info.tiktok.tiktok_engagement_rate",
    title: "TikTok Etkileşim Oranı",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.user_info.tiktok?.tiktok_engagement_rate)}</div>
    },
  },
  {
    accessor: "user_info.tiktok.hearts",
    title: "TikTok Kalp Sayısı",
    sortable: true,
  },
]
