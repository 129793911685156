import React from "react"

interface RadioInputProps {
  placeholder: string
  value: string
  option1: string
  option2: string
  label1: string
  label2: string
  onChange: (value: string) => void
}

const RadioInput: React.FC<RadioInputProps> = ({
  placeholder,
  value,
  option1,
  option2,
  label1,
  label2,
  onChange,
}) => {
  return (
    <div className="md:flex md:flex-col mr-2 ml-2">
      <h2 className="text-sm font-bold mb-2">{placeholder}</h2>
      <label>
        <input
          type="radio"
          value={option1}
          checked={value === option1}
          onChange={(e) => onChange(e.target.value)}
          className="form-radio text-pink-600 mr-2"
        />
        {label1}
      </label>
      <label>
        <input
          type="radio"
          value={option2}
          checked={value === option2}
          onChange={(e) => onChange(e.target.value)}
          className="form-radio text-pink-600 mr-2"
        />
        {label2}
      </label>
      <label>
        <input
          type="radio"
          value=""
          checked={value === ""}
          onChange={(e) => onChange(e.target.value)}
          className="form-radio text-pink-600 mr-2"
        />
        Tümü
      </label>
    </div>
  )
}

export default RadioInput
