import React, { useState } from "react"
import { hobbiesList } from "../../../../static/Hobbies"

const HobbieSelection = ({
  isSelected,
  toggleHobbySelection,
}: {
  isSelected: (value: string) => boolean
  toggleHobbySelection: (value: string) => void
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className="w-full bg-gray-100 rounded-2xl py-3 items-center border border-gray-200">
      <h2
        className="font-semibold text-gray-400 mt-1 ml-3 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        Hobiler
      </h2>
      {isOpen &&
        hobbiesList.map((group) => (
          <div key={group.group_name} className="space-x-2 space-y-2 px-3">
            <div className="flex flex-wrap">
              {group.items.map((item) => (
                <button
                  type="button"
                  key={item.name}
                  onClick={() => toggleHobbySelection(item.name)}
                  className={`rounded-3xl p-2 text-center cursor-pointer m-1 ${
                    isSelected(item.name) ? "bg-rose-500 text-white" : "bg-neutral-50 text-black"
                  }`}
                >
                  {item.native_name}
                </button>
              ))}
            </div>
          </div>
        ))}
    </div>
  )
}
export default HobbieSelection
