import { faEye, faHeartPulse, faBan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Link } from "react-router-dom"

export const columnConfig = ({
  renderCampaignId,
  renderDescriptionCell,
  verifiedIcon,
  handleToggleVisibility,
  loading,
  setSortBy,
  handleSpamCampaign,
}) => [
  {
    accessor: "_id",
    title: "Kampanya",
    sortable: false,
    render: ({ _id }: any) => (
      <Link to={`/campaigns/find/${_id}`}>
        <div className="text-center items-center mr-4">
          <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
        </div>
      </Link>
    ),
  },

  {
    accessor: "brand",
    title: "Marka",
    sortable: false,
    render: ({ brand }: any) => (
      <Link to={`/brands/find/${brand}`}>
        <div className="text-center items-center mr-4">
          <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
        </div>
      </Link>
    ),
  },
  { accessor: "id", title: "Id", sortable: true, render: renderCampaignId },
  {
    accessor: "name",
    title: "İsim",
    sortable: true,
    render: ({ name }) => (
      <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>{name}</div>
    ),
  },
  {
    accessor: "description",
    title: "Açıklama",
    sortable: true,
    render: renderDescriptionCell,
  },
  { accessor: "country", title: "Ülke", sortable: true },
  { accessor: "platform", title: "Platform", sortable: true },
  {
    accessor: "is_verified",
    title: "Onay Durumu",
    sortable: true,
    render: ({ is_verified }) => (
      <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
        {verifiedIcon(is_verified)}
      </div>
    ),
  },
  {
    accessor: "visibility",
    title: "Görünürlük Durumu",
    sortable: true,
    render: ({ _id, visibility }) => (
      <div className="flex">
        <FontAwesomeIcon
          icon={faHeartPulse}
          size="lg"
          style={{ color: visibility ? "#009e1a" : "#ff0000" }}
        />
        <p className="ml-2">{visibility ? "Aktif" : "Görünür Değil"}</p>
      </div>
    ),
  },
  {
    accessor: "toggle_visibility",
    title: "Görünürlüğü Değiştir",
    sortable: true,
    render: ({ _id, visibility }) => {
      return (
        <div className="flex">
          <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
            <button
              onClick={() => handleToggleVisibility(_id, visibility ? "false" : "true")}
              className="bg-blue-500 text-white rounded-md px-3 py-2 w-full hover:bg-blue-600"
            >
              {loading ? (
                <span className="spinner"></span>
              ) : visibility ? (
                "Yayından Kaldır"
              ) : (
                "Aktifleştir"
              )}
            </button>
          </div>
        </div>
      )
    },
  },
  {
    accessor: "created_at",
    title: <div onClick={() => setSortBy("created_at")}>Oluşturulma Tarihi</div>,
    sortable: true,
    render: ({ created_at }: any) => (
      <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
        {new Date(created_at).toLocaleDateString()}
      </div>
    ),
  },
  {
    accessor: "is_spam",
    title: "Spam Durumu",
    render: ({ _id }: any) => (
      <div>
        <FontAwesomeIcon
          icon={faBan}
          style={{ color: "green", cursor: "pointer", marginLeft: "10px" }}
          onClick={() => handleSpamCampaign(_id)}
        />
      </div>
    ),
  },
  {
    accessor: "max_cost",
    title: <div onClick={() => setSortBy("max_cost")}>Max Tutar</div>,
    sortable: true,
    render: ({ max_cost }: any) => (
      <div style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis" }}>
        {max_cost && max_cost.toLocaleString()}
      </div>
    ),
  },
  {
    accessor: "application_counts.application_done",
    title: (
      <div onClick={() => setSortBy("application_counts.application_done")}>
        Tamamlanan İşbirlikleri
      </div>
    ),
    sortable: true,
  },

  // {
  //   accessor: "application_counts.content_shared",
  //   title: (
  //     <div onClick={() => setSortBy("application_counts.content_shared")}>
  //       Paylaşılan İçerikler
  //     </div>
  //   ),
  //   sortable: true,
  // },
  {
    accessor: "application_counts.content_to_share",
    title: (
      <div onClick={() => setSortBy("application_counts.content_to_share")}>
        Paylaşılacak İçerikler
      </div>
    ),
    sortable: true,
  },
  {
    accessor: "application_counts.content_approved",
    title: (
      <div onClick={() => setSortBy("application_counts.content_approved")}>
        {" "}
        Onaylanan İçerikler
      </div>
    ),
    sortable: true,
  },
  {
    accessor: "application_counts.waiting_content",
    title: (
      <div onClick={() => setSortBy("application_counts.waiting_content")}>İçerik Bekleyen</div>
    ),
    sortable: true,
  },
  {
    accessor: "application_counts.first_application",
    title: <div onClick={() => setSortBy("application_counts.first_application")}>İlk Başvuru</div>,
    sortable: true,
  },
]
