import React, { useEffect, useState } from "react"
import TiktokProfilePicture from "./TiktokProfilePicture"
import InstagramProfilePicture from "./InstagramProfilePicture"
import ReadMore from "./ReadMore"
import "./styles/styles.css"
import {
  TAbanUser,
  TAchangePhone,
  TAchangeUserBalance,
  TArecoverAccount,
  TAremoveBan,
  TAremoveVerification,
  TAuserIsSpam,
  TAuserUnvisible,
  TAverifyUser,
} from "../services/userAPI"
import { UpdateInsta } from "./updateInstaModal"
import { UpdateTiktok } from "./updateTiktokModal"
import { InstaData, SharedPost, TiktokData, User, UserMoney } from "../types/UserData"
import VideoPlayer from "./VideoPlayer"
import { numFixer } from "../pages/User/getAllUsers/components/Functions"

const UserProfile = (data: User) => {
  const [_id, setId] = useState(data._id)
  const [birthday, setBirthday] = useState(data.birthday)
  const [name, setName] = useState(data.name)
  const [email, setEmail] = useState(data.email)
  const [phone, setPhone] = useState(data.phone)
  const [editPhone, setEditPhone] = useState(data.phone)
  const [is_banned, setIsBanned] = useState(data.is_banned)
  const [job, setJob] = useState(data.job)
  const [country, setCountry] = useState(data.country)
  const [city, setCity] = useState(data.city)
  const [gender, setGender] = useState(data.gender)
  const [deleted, setDeleted] = useState(data.deleted)
  const [editor, setEditor] = useState(false)
  const [changeBalanceEditor, setChangeBalanceEditor] = useState(false)
  const [newBalance, setNewBalance] = useState(data.money.current)
  const [hobbies, setHobbies] = useState(data.hobbies)
  const [verification, setVerification] = useState(data.verification)
  const [isUpdateInstaOpen, setIsUpdateInstaOpen] = useState(false)
  const [isUpdateTiktokOpen, setIsUpdateTiktokOpen] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [instaData, setInstaData] = useState<InstaData>(data.insta)
  const [sharedPostData, setSharedPostData] = useState<SharedPost[]>(data.insta.shared_posts)
  const [tiktokData, setTiktokData] = useState<TiktokData>(data.tiktok)
  const [videos, setVideos] = useState(data.tiktok.videos)
  const [moneyData, setMoneyData] = useState<UserMoney>(data.money)
  const [notification, setNotification] = useState("")
  const [error, setError] = useState("")
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null)

  const personalInfo: { key: string; value: any }[] = [
    { key: "İsim:", value: name },
    { key: "Doğum Tarihi:", value: birthday },
    { key: "Email:", value: email },
    { key: "Meslek:", value: job },
    { key: "Ülke:", value: country },
    { key: "Şehir:", value: city },
    { key: "Cinsiyet:", value: gender },
    { key: "Hobiler:", value: hobbies.join(", ") },
    { key: "Papara Hesap No:", value: moneyData?.paparaAccountNo },
  ]

  const warnings = instaData.warnings.map((warning) => warning.native_name)

  const instagramInfo: { key: string; value: any }[] = [
    { key: "Kullanıcı Adı:", value: instaData.username },
    { key: "Tam İsim:", value: instaData.full_name },
    { key: "Biyografi:", value: instaData.biography },
    { key: "Takipçi:", value: instaData.followers },
    { key: "Takip:", value: instaData.following },
    { key: "Gönderi Sayısı:", value: instaData.post_number },
    { key: "Ortalama Beğeni:", value: numFixer(instaData.average_like) },
    { key: "Etkileşim Oranı:", value: numFixer(instaData.engagement_rate) },
    { key: "Nesneler:", value: instaData.keywords.join(", ") },
    { key: "Uyarılar:", value: warnings.join(", ") },
    { key: "Spam Durumu:", value: instaData.is_spam ? "True" : "False" },
    { key: "Görünürlük Durumu:", value: instaData.unvisible ? "True" : "False" },
  ]

  const tiktokInfo: { key: string; value: any }[] = [
    { key: "Kullanıcı Adı:", value: tiktokData.username },
    { key: "Takma Ad:", value: tiktokData.username },
    { key: "Takipçi:", value: tiktokData.followers },
    { key: "Takip:", value: tiktokData.following },
    { key: "Kalp Sayısı:", value: tiktokData.hearts },
    { key: "Ortalama Beğeni:", value: numFixer(tiktokData.tiktok_average_like) },
    { key: "Etkileşim Oranı:", value: numFixer(tiktokData.tiktok_engagement_rate) },
  ]

  const recoverAccount = async (id: any, status: any) => {
    try {
      const response = await TArecoverAccount(id, status)
      if (response) {
        setDeleted(status)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const changePhone = async (id: any, phone: any) => {
    try {
      const response = await TAchangePhone(id, phone)
      setEditPhone(phone)
      if (response) {
        setPhone(phone)
        setEditor(false)
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error
      if (errorMessage && errorMessage.startsWith("E11000")) {
        setError("Telefon numarası mevcut, ilk önce mevcut telefon numarasını değiştirin.")
      }
    }
  }

  const removeVerification = async (id: any) => {
    try {
      const response = await TAremoveVerification(id)
      if (response) {
        setVerification(false)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const addVerification = async (id: any) => {
    try {
      const response = await TAverifyUser(id, "true")
      if (response) {
        setVerification(true)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const changeSpam = async (id: any, status: any) => {
    try {
      const response = await TAuserIsSpam(id, status)
      if (response) {
        setInstaData({
          ...instaData,
          is_spam: status,
        })
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const changeUnvisible = async (id: any, status: any) => {
    try {
      const response = await TAuserUnvisible(id, status)
      if (response) {
        setInstaData({
          ...instaData,
          unvisible: status,
        })
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const changeUserBalance = async (id: string, balance: number) => {
    try {
      const response = await TAchangeUserBalance(id, balance)
      if (response) {
        setMoneyData({
          ...moneyData,
          current: balance,
        })
        setChangeBalanceEditor(false)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    if (notification) {
      const notificationTimeout = setTimeout(() => {
        setNotification("")
      }, 5000)

      return () => clearTimeout(notificationTimeout)
    }
  }, [notification])

  useEffect(() => {
    if (error) {
      const errorTimeout = setTimeout(() => {
        setError("")
      }, 5000)

      return () => clearTimeout(errorTimeout)
    }
  }, [error])

  const handleChangePhone = (e: any) => {
    const value = e.target.value.replace(/[^0-9]/g, "")
    setEditPhone(`+${value}`)
  }

  const renderKeywords = (keywords: any) => {
    const toggleExpandedRow = () => {
      setIsExpanded(!isExpanded)
    }
    if (isExpanded) {
      return (
        <div>
          {keywords}
          <button
            type="button"
            onClick={toggleExpandedRow}
            className="text-blue-500 hover:underline"
          >
            Less
          </button>
        </div>
      )
    } else if (keywords.length > 100) {
      return (
        <div>
          {`${keywords.slice(0, 100)}... `}
          <button
            type="button"
            onClick={toggleExpandedRow}
            className="text-blue-500 hover:underline"
          >
            More
          </button>
        </div>
      )
    } else {
      return <div>{keywords}</div>
    }
  }

  const banUser = async (id: any) => {
    try {
      const response = await TAbanUser(id)
      if (response) {
        setIsBanned(true)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const removeBan = async (id: any) => {
    try {
      const response = await TAremoveBan(id)
      if (response) {
        setIsBanned(false)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  return (
    <>
      <div className="profile-container p-14 rounded-lg  w-2/3">
        <div className="flex items-center mb-20">
          <div className="flex flex-row items-center mr-16">
            <div>
              <InstagramProfilePicture instagramData={instaData} />
              <button
                type="button"
                onClick={() => setIsUpdateInstaOpen(true)}
                className="btn btn-primary mt-5"
              >
                Instagramı Güncelle
              </button>

              {isUpdateInstaOpen && (
                <UpdateInsta
                  username={instaData.username}
                  _id={_id}
                  onClose={() => setIsUpdateInstaOpen(false)}
                />
              )}
            </div>
            <div>
              <TiktokProfilePicture tiktokData={tiktokData} />
              <button
                type="button"
                onClick={() => setIsUpdateTiktokOpen(true)}
                className="btn btn-primary mt-5 ml-7"
              >
                TikToku Güncelle
              </button>

              {isUpdateTiktokOpen && (
                <UpdateTiktok
                  username={tiktokData.username}
                  _id={_id}
                  onClose={() => setIsUpdateTiktokOpen(false)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="profile-section bg-white p-3 shadow-md mb-3 rounded-2xl">
        {notification && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-3">
            <span className="flex items-center block sm:inline">{notification}</span>
          </div>
        )}
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-3">
            <span className="flex items-center block sm:inline">{error}</span>
          </div>
        )}
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">Kişisel Bilgiler</h3>
        <table className="table-responsive">
          <tbody>
            {personalInfo.map((info: { key: string; value: any }) => (
              <tr key={info.key}>
                <td>{info.key}</td>
                <td>{info.value}</td>
              </tr>
            ))}
          </tbody>
          <tbody>
            <tr>
              <td>Bakiye:</td>
              <td>{moneyData.current}</td>
              <button
                className="text-indigo-600 hover:text-indigo-900 pt-1 pb-1"
                onClick={() => setChangeBalanceEditor(!changeBalanceEditor)}
              >
                Bakiyeyi Güncelle
              </button>
              {changeBalanceEditor && (
                <div className="flex flex-col space-y-2 pb-2">
                  <div className="flex flex-row space-x-1">
                    <input
                      id="balance"
                      type="number"
                      placeholder="Yeni bakiye"
                      value={newBalance}
                      className="form-input text-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-60 pt-1 pb-1 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                      onChange={(e) => setNewBalance(e.target.valueAsNumber)}
                    />
                    <button
                      className="text-sm font-medium text-indigo-600 hover:text-indigo-900"
                      onClick={() => changeUserBalance(_id, newBalance)}
                    >
                      Güncelle
                    </button>
                  </div>
                </div>
              )}
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>Onay Durumu:</td>
              <td>{verification ? "Onaylı" : "Onaylı Değil"}</td>
              {verification === true ? (
                <button
                  className="text-indigo-600 hover:text-indigo-900 pt-3"
                  onClick={() => removeVerification(_id)}
                >
                  Onayı Kaldır
                </button>
              ) : (
                <button
                  className="text-indigo-600 hover:text-indigo-900 pt-3"
                  onClick={() => addVerification(_id)}
                >
                  Onayla
                </button>
              )}
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>Telefon:</td>
              <td>{phone}</td>
              <button
                className="text-indigo-600 hover:text-indigo-900 pt-1 pb-1"
                onClick={() => setEditor(!editor)}
              >
                Telefon Numarasını Güncelle
              </button>
              {editor && (
                <div className="flex flex-col space-y-2 pb-2">
                  <div className="flex flex-row space-x-1">
                    <input
                      id="phone"
                      type="tel"
                      placeholder="(ex: 905555555555)"
                      value={editPhone}
                      className="form-input text-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 w-60 pt-1 pb-1"
                      onChange={handleChangePhone}
                    />
                    <button
                      className="text-sm font-medium text-indigo-600 hover:text-indigo-900"
                      onClick={() => changePhone(_id, editPhone)}
                    >
                      Güncelle
                    </button>
                  </div>
                </div>
              )}
            </tr>
            <tr>
              <td>Hesap Silinmişlik Durumu:</td>
              <td>{deleted ? "Silinmiş" : "Silinmemiş"}</td>
              {deleted === true && (
                <button
                  className="text-indigo-600 hover:text-indigo-900 pt-3"
                  onClick={() => recoverAccount(_id, false)}
                >
                  Hesabı Kurtar
                </button>
              )}
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>Ban Durumu:</td>
              <td>{is_banned ? "Banlanmış" : "Banlanmamış"}</td>
              {is_banned === true ? (
                <button
                  className="text-indigo-600 hover:text-indigo-900 pt-3"
                  onClick={() => removeBan(_id)}
                >
                  Ban Kaldır
                </button>
              ) : (
                <button
                  className="text-indigo-600 hover:text-indigo-900 pt-3"
                  onClick={() => banUser(_id)}
                >
                  Banla
                </button>
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="profile-section bg-white p-3 shadow-md mb-3 rounded-2xl">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">Instagram Bilgileri</h3>
        <table className="table-responsive">
          <tbody>
            {instagramInfo.map((info: { key: string; value: any }) => (
              <tr key={info.key}>
                {info.key === "Spam Durumu:" ? (
                  <>
                    <td className="py-2 px-4 font-semibold">Spam Durumu:</td>
                    <td className="py-2 px-4">{info.value}</td>
                    <td className="py-2 px-4">
                      <button
                        className="bg-indigo-600 text-white font-medium py-1 px-3 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                        onClick={() => changeSpam(_id, !instaData.is_spam)}
                      >
                        Spam Durumunu Değiştir
                      </button>
                    </td>
                  </>
                ) : info.key === "Görünürlük Durumu:" ? (
                  <>
                    <td className="py-2 px-4 font-semibold">Görünmezlik Durumu:</td>
                    <td className="py-2 px-4">{info.value}</td>
                    <td className="py-2 px-4">
                      <button
                        className="bg-indigo-600 text-white font-medium py-1 px-3 rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
                        onClick={() => changeUnvisible(_id, !instaData.unvisible)}
                      >
                        Görünmezliği Değiştir
                      </button>
                    </td>
                  </>
                ) : info.key === "Nesneler:" ? (
                  <>
                    <td className="py-2 px-4 font-semibold">Nesneler:</td>
                    <td className="py-2 px-4">{renderKeywords(info.value)}</td>
                  </>
                ) : (
                  <>
                    <td>{info.key}</td>
                    <td>{info.value}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="profile-section bg-white p-3 shadow-md mb-3 rounded-2xl">
        <h3 className="text-2xl font-semibold mb-4 text-gray-800">TikTok Bilgileri</h3>
        <table className="table-responsive">
          <tbody>
            {tiktokInfo.map((info: { key: string; value: any }) => (
              <tr key={info.key}>
                <td>{info.key}</td>
                <td>{info.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <h2 className="section-title text-xl font-bold my-5">Instagram Postları</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
        {sharedPostData.map((post) => (
          <div
            key={post.id}
            className="flex flex-col bg-white rounded-lg shadow-md overflow-hidden w-full"
          >
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover zoom"
                src={post.media_url}
                alt={post.description}
              />
            </div>
            <div className="px-6 py-4">
              <div className="font-bold text-xl mb-2">{post.location?.name}</div>
              {post.description?.length > 150 ? (
                <ReadMore content={post.description} maxCharacterCount={150} />
              ) : (
                <p className="text-gray-700 text-base">{post.description}</p>
              )}
            </div>
            <div className="px-6 mt-auto pb-2">
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                Beğeniler: {post.like_count}
              </span>
              <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                Yorumlar: {post.comment_count}
              </span>
            </div>
          </div>
        ))}
      </div>
      <h2 className="section-title text-xl font-bold my-5">TikTok Videoları</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
        {videos?.map((video) => (
          <div
            key={video.media_url}
            className="flex flex-col bg-white rounded-lg shadow-md overflow-hidden w-full"
            onClick={() => {
              setSelectedVideo(video.media_url)
            }}
          >
            <div className="flex-shrink-0">
              <img
                className="h-48 w-full object-cover zoom"
                src={video.cover}
                alt={video.description}
              />
            </div>
            <div className="p-2">
              {video.description && video.description.length > 150 ? (
                <ReadMore content={video.description} maxCharacterCount={50} />
              ) : (
                <p className="text-gray-700 text-sm">{video.description}</p>
              )}
            </div>
            <div className="p-2">
              <span className="inline-block bg-gray-200 rounded-full px-2 py-0.5 text-xs font-semibold text-gray-700 mr-1 mb-1">
                Beğeniler: {video.like_count}
              </span>
              <span className="inline-block bg-gray-200 rounded-full px-2 py-0.5 text-xs font-semibold text-gray-700 mr-1 mb-1">
                Yorumlar: {video.comment_count}
              </span>
            </div>
          </div>
        ))}
        {selectedVideo && (
          <VideoPlayer selectedVideo={selectedVideo} onClose={() => setSelectedVideo("")} />
        )}
      </div>
    </>
  )
}

export default UserProfile
