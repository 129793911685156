import React from "react"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { TAfindAllApprovalUser } from "../../../services/userAPI"
import { User } from "../../../types/UserData"
import { DataTable, DataTableSortStatus } from "mantine-datatable"
import sortBy from "lodash/sortBy"
import { FilterValue } from "../../../types/FilterData"
import DownloadCSVButton from "../../../components/DownloadCSVButton"
import { selectWaitingApprovalUserFilters } from "../../../redux/store/filters/user/waitingApprovalUserFilterSlice"
import { columnConfig } from "./components/columns"
import { generateColumn } from "../bannedUsers/components/columns"
import Filters from "./components/Filters"

const fetchData = async (
  page: number,
  perPage: number,
  query: any,
  sortBy: string,
  sortOrder: boolean,
) => {
  try {
    const response = await TAfindAllApprovalUser(page, perPage, query, sortBy, sortOrder)
    if (response && Array.isArray(response.verificationProfiles)) {
      const totalRecords = response.totalDocuments
      const data = response.verificationProfiles.map((item: any, index: any) => {
        return {
          id: index + 1,
          ...item,
        }
      })
      return { data, totalRecords }
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

const WaitingApprovalUser = () => {
  const waitingApprovalUserFilters = useSelector(selectWaitingApprovalUserFilters)

  const [page, setPage] = useState(1)
  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [totalRecords, setTotalRecords] = useState(0)
  const [initialRecords, setInitialRecords] = useState(sortBy([] as User[], "id"))
  const sortStatus: DataTableSortStatus = { columnAccessor: "id", direction: "asc" }
  const [sortByColumn, setSortByColumn] = useState("")
  const [sortOrder, setSortOrder] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const handleFetchData = async () => {
    setLoading(true)

    const flattenFilters = Object.entries(waitingApprovalUserFilters).reduce(
      (acc, [key, filter]) => {
        const simpleKeys = [
          "gender",
          "verification",
          "warnings",
          "platform",
          "is_spam",
          "unvisible",
          "last_login",
          "frequency",
          "country",
          "city",
          "job",
          "insta_username",
          "tiktok_username",
        ]

        if (simpleKeys.includes(key) && typeof filter === "string") {
          acc[key] = filter
        } else if (filter && typeof filter === "object") {
          const { min, max } = filter as FilterValue
          if (min) {
            acc[`min_${key}`] = min
          }
          if (max) {
            acc[`max_${key}`] = max
          }
        }

        return acc
      },
      {} as { [key: string]: string },
    )

    const params = new URLSearchParams(flattenFilters)

    const keywords = (waitingApprovalUserFilters.keywords as string[])?.map(
      (keyword) => keyword.charAt(0).toUpperCase() + keyword.slice(1),
    )
    keywords?.forEach((keywords) => {
      params.append("keywords", keywords)
    })

    const hobbies = (waitingApprovalUserFilters.hobbies as string[]).map(
      (hobby) => hobby.charAt(0) + hobby.slice(1),
    )
    hobbies.forEach((hobbies) => {
      params.append("hobbies", hobbies)
    })
    try {
      const data: any = await fetchData(page, pageSize, params, sortByColumn, sortOrder)
      if (data !== undefined) {
        setInitialRecords(data.data)
        setTotalRecords(data.totalRecords)
      } else {
        setError("No data found")
      }
      setLoading(false)
    } catch (error) {
      setError("No data found")
    }
  }

  const handleSorting = (sortyByColumn: string) => {
    switch (sortyByColumn) {
      case "insta_post_number":
        setSortByColumn("insta.post_number")
        break
      case "insta_followers":
        setSortByColumn("insta.followers")
        break
      case "insta_average_like":
        setSortByColumn("insta.average_like")
        break
      case "tiktok_followers":
        setSortByColumn("tiktok.followers")
        break
      case "tiktok_average_like":
        setSortByColumn("tiktok.tiktok_average_like")
        break
      case "tiktok_engagement_rate":
        setSortByColumn("tiktok.tiktok_engagement_rate")
        break
      case "tiktok_hearts":
        setSortByColumn("tiktok.hearts")
        break
      default:
        setSortByColumn(sortyByColumn)
        break
    }
    setSortOrder((prevOrder) => !prevOrder)
  }

  useEffect(() => {
    handleFetchData()
  }, [page, pageSize, sortByColumn, sortOrder])

  useEffect(() => {
    setPage(1)
  }, [pageSize, waitingApprovalUserFilters])

  const renderBrandId = (record: any, index: number) => {
    const itemsPerPage = page * pageSize
    const recordIndex = itemsPerPage + index
    const brandId = recordIndex - pageSize + 1
    return <div>{brandId}</div>
  }

  const columns = columnConfig({
    renderBrandId,
  }).map((col) => generateColumn(col.accessor, col.title, col.sortable, col.render))

  return (
    <div className="panel">
      <Filters
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        handleFetchData={handleFetchData}
      />
      <div className=" flex md:items-center md:flex-row flex-col gap-5">
        <div
          className="flex w-1/10 bg-gray-100 ml-4 items-center border border-gray-300 rounded-3xl mb-4 p-2 cursor-pointer"
          onClick={() => setIsFilterOpen(true)}
        >
          Filtrele
        </div>
        <div className="flex flex-row ml-auto text-center mb-4 mr-2">
          <button
            className=" inline-flex items-center justify-center ml-2 p-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-3xl text-white bg-blue-600 hover:bg-blue-700"
            onClick={() => handleFetchData()}
          >
            Kullanıcıları Ara
          </button>
          <DownloadCSVButton
            className=" inline-flex items-center justify-center ml-2 px-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-3xl text-white bg-green-600 hover:bg-green-700"
            userData={initialRecords}
          />
        </div>
      </div>
      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={initialRecords}
            columns={columns}
            totalRecords={totalRecords}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={(e) => handleSorting(e.columnAccessor)}
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
          />
        )}
      </div>
    </div>
  )
}

export default WaitingApprovalUser
