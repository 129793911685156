import { useEffect, useState } from "react"
import React from "react"
import { TAfindApprovalUser, TAverifyUser } from "../../../services/userAPI"
import ReadMore from "../../../components/ReadMore"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faVenus, faMars } from "@fortawesome/free-solid-svg-icons"
import { FilterValue } from "../../../types/FilterData"
import { selectDoApprovalUserFilters } from "../../../redux/store/filters/user/doApprovalUserFilterSlice"
import { SharedPost, TiktokVideo, User } from "../../../types/UserData"
import VideoPlayer from "../../../components/VideoPlayer"
import Filters from "./components/Filters"
import { numFixer } from "../getAllUsers/components/Functions"

const DoApprovalScreen = () => {
  const doApprovalUserFilters = useSelector(selectDoApprovalUserFilters)

  const [data, setData] = useState<User[]>([])
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const handleFetchData = async () => {
    setIsLoading(true)
    try {
      const flattenFilters = Object.entries(doApprovalUserFilters).reduce((acc, [key, filter]) => {
        const simpleKeys = [
          "gender",
          "verification",
          "warnings",
          "platform",
          "is_spam",
          "unvisible",
          "last_login",
          "frequency",
          "country",
          "city",
          "job",
          "insta_username",
          "tiktok_username",
        ]

        if (simpleKeys.includes(key) && typeof filter === "string") {
          acc[key] = filter
        } else if (key === "keywords" || key === "hobbies") {
          console.log("")
        } else if (filter && typeof filter === "object") {
          const { min, max } = filter as FilterValue
          if (min) {
            acc[`min_${key}`] = min
          }
          if (max) {
            acc[`max_${key}`] = max
          }
        }

        return acc
      }, {} as { [key: string]: string })

      const params = new URLSearchParams(flattenFilters)

      const keywords = (doApprovalUserFilters.keywords as string[])?.map(
        (keyword) => keyword.charAt(0).toUpperCase() + keyword.slice(1),
      )
      keywords?.forEach((keywords) => {
        params.append("keywords", keywords)
      })

      const hobbies = (doApprovalUserFilters.hobbies as string[]).map(
        (hobby) => hobby.charAt(0) + hobby.slice(1),
      )
      hobbies.forEach((hobbies) => {
        params.append("hobbies", hobbies)
      })

      const response = await TAfindApprovalUser(params)
      if (response && Array.isArray(response)) {
        setData(response)
      } else {
        setError("No data found")
      }
    } catch (error) {
      setError("Error fetching data")
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [])

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < data.length - 1 ? prevIndex + 1 : prevIndex))
  }

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
  }

  const handleApprove = async (id: string, status: string) => {
    setIsLoading(true)
    try {
      const response = await TAverifyUser(id, status)
      if (!response) {
        setError("Response is null")
        return
      }
      setIsLoading(false)
      if (currentIndex < data.length - 1) {
        handleNext()
      }
    } catch (error: any) {
      setError(error.message)
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const genderIcon = (gender: string) => {
    switch (gender) {
      case "male":
        return <FontAwesomeIcon icon={faMars} style={{ color: "#0000ff" }} />
      case "female":
        return <FontAwesomeIcon icon={faVenus} style={{ color: "#ff00dd" }} />
      default:
        return null
    }
  }

  const personalInfo: { key: string; value: any }[] = [
    { key: "İsim:", value: data[currentIndex]?.name },
    { key: "Doğum Tarihi:", value: data[currentIndex]?.birthday },
    { key: "Email:", value: data[currentIndex]?.email },
    { key: "Meslek:", value: data[currentIndex]?.job },
    { key: "Ülke:", value: data[currentIndex]?.country },
    { key: "Şehir:", value: data[currentIndex]?.city },
    { key: "Cinsiyet:", value: data[currentIndex]?.gender },
    { key: "Hobiler:", value: data[currentIndex]?.hobbies.join(", ") },
  ]

  const instagramInfo: { key: string; value: any }[] = [
    { key: "Kullanıcı Adı:", value: data[currentIndex]?.insta?.username },
    { key: "Tam İsim:", value: data[currentIndex]?.insta?.full_name },
    { key: "Biyografi:", value: data[currentIndex]?.insta?.biography },
    { key: "Takipçi:", value: data[currentIndex]?.insta?.followers },
    { key: "Takip:", value: data[currentIndex]?.insta?.following },
    { key: "Gönderi Sayısı:", value: data[currentIndex]?.insta?.post_number },
    { key: "Ortalama Beğeni:", value: numFixer(data[currentIndex]?.insta?.average_like || 0) },
    { key: "Etkileşim Oranı:", value: numFixer(data[currentIndex]?.insta?.engagement_rate || 0) },
    { key: "Spam Durumu:", value: data[currentIndex]?.insta?.is_spam ? "True" : "False" },
    { key: "Görünürlük Durumu:", value: data[currentIndex]?.insta?.unvisible ? "True" : "False" },
  ]

  const tiktokInfo: { key: string; value: any }[] = [
    { key: "Kullanıcı Adı:", value: data[currentIndex]?.tiktok?.username },
    { key: "Takma Ad:", value: data[currentIndex]?.tiktok?.username },
    { key: "Takipçi:", value: data[currentIndex]?.tiktok?.followers },
    { key: "Takip:", value: data[currentIndex]?.tiktok?.following },
    { key: "Kalp Sayısı:", value: data[currentIndex]?.tiktok?.hearts },
    { key: "Ortalama Beğeni:", value: numFixer(data[currentIndex]?.tiktok?.tiktok_average_like) },
    {
      key: "Etkileşim Oranı:",
      value: numFixer(data[currentIndex]?.tiktok?.tiktok_engagement_rate),
    },
  ]

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-40">
        <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
      </div>
    )
  }

  if (error) {
    return <p>Hata: {error}</p>
  }

  return (
    <div className="flex flex-col lg:flex-col">
      <Filters
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        handleFetchData={handleFetchData}
      />
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/3 mr-4">
          <div className="flex flex-row justify-between">
            <div>
              <button
                onClick={() => handleApprove(data[currentIndex]._id, "false")}
                className="mt-2 mr-2 py-2 px-4 rounded-md bg-red-500 text-white hover:bg-red-600"
              >
                Red
              </button>
              <button
                onClick={() => handleApprove(data[currentIndex]._id, "true")}
                className="mt-2 mr-2 py-2 px-4 rounded-md bg-green-500 text-white hover:bg-green-600"
              >
                Onay
              </button>
            </div>
            <div
              className="flex bg-gray-100 ml-4 mt-2 items-center border border-gray-300 rounded-3xl mb-4 p-2 cursor-pointer"
              onClick={() => setIsFilterOpen(true)}
            >
              Filtrele
            </div>
            <div>
              <button
                onClick={handlePrevious}
                className="mt-2 mr-2 py-2 px-4 rounded-md bg-blue-500 text-white"
              >
                Geri
              </button>
              <button
                onClick={handleNext}
                className="mt-2 mr-2 py-2 px-4 rounded-md bg-blue-500 text-white"
              >
                İleri
              </button>
            </div>
          </div>
          {data.length > 0 && (
            <div className="datatables">
              <div className="w-full max-w-xl mt-4 rounded-md shadow-sm ">
                <h3 className="text-xl font-semibold mb-1 text-gray-800">Kişisel Bilgiler</h3>
                <table className="table-responsive">
                  <tbody>
                    {personalInfo.map((info: { key: string; value: any }) => (
                      <tr key={info.key}>
                        <td>{info.key}</td>
                        <td>{info.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <h3 className="text-xl font-semibold mb-1 mt-2 text-gray-800">
                  Instagram Bilgileri
                </h3>
                <table className="table-responsive">
                  <tbody>
                    {instagramInfo.map((info: { key: string; value: any }) => (
                      <tr key={info.key}>
                        <td>{info.key}</td>
                        <td>{info.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <h3 className="text-xl font-semibold mb-1 mt-2 text-gray-800">TikTok Bilgileri</h3>
                <table className="table-responsive">
                  <tbody>
                    {tiktokInfo.map((info: { key: string; value: any }) => (
                      <tr key={info.key}>
                        <td>{info.key}</td>
                        <td>{info.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div className="w-full lg:w-2/3 h-screen mt-4 lg:mt-0 p-10">
          <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-1">
            {data.length > 0 &&
              data[currentIndex]?.insta?.shared_posts &&
              data[currentIndex]?.insta?.shared_posts.map((post: SharedPost) => (
                <div
                  key={post.id}
                  className="flex flex-col bg-white rounded-lg shadow-sm overflow-hidden max-w-xs mx-2 mb-2"
                >
                  <img
                    className="h-32 w-full object-cover zoom"
                    src={post.media_url}
                    alt={post.description}
                  />
                  <div className="p-2">
                    {post.location && post.location.name && (
                      <div className="font-semibold text-md mb-1">{post.location.name}</div>
                    )}
                    {post.description && post.description.length > 150 ? (
                      <ReadMore content={post.description} maxCharacterCount={50} />
                    ) : (
                      <p className="text-gray-700 text-sm">{post.description}</p>
                    )}
                  </div>
                  <div className="p-2">
                    <span className="inline-block bg-gray-200 rounded-full px-2 py-0.5 text-xs font-semibold text-gray-700 mr-1 mb-1">
                      Beğeniler: {post.like_count}
                    </span>
                    <span className="inline-block bg-gray-200 rounded-full px-2 py-0.5 text-xs font-semibold text-gray-700 mr-1 mb-1">
                      Yorumlar: {post.comment_count}
                    </span>
                  </div>
                </div>
              ))}

            {data.length > 0 &&
              data[currentIndex]?.tiktok?.videos &&
              data[currentIndex]?.tiktok?.videos?.map((video: TiktokVideo) => (
                <div
                  key={video.media_url}
                  className="flex flex-col bg-white rounded-lg shadow-sm overflow-hidden max-w-xs mx-2 mb-2"
                  onClick={() => {
                    setSelectedVideo(video.media_url)
                  }}
                >
                  <img
                    className="h-32 w-full object-cover zoom"
                    src={video.cover}
                    alt={video.description}
                  />
                  <div className="p-2">
                    {video.description && video.description.length > 150 ? (
                      <ReadMore content={video.description} maxCharacterCount={50} />
                    ) : (
                      <p className="text-gray-700 text-sm">{video.description}</p>
                    )}
                  </div>
                  <div className="p-2">
                    <span className="inline-block bg-gray-200 rounded-full px-2 py-0.5 text-xs font-semibold text-gray-700 mr-1 mb-1">
                      Beğeniler: {video.like_count}
                    </span>
                    <span className="inline-block bg-gray-200 rounded-full px-2 py-0.5 text-xs font-semibold text-gray-700 mr-1 mb-1">
                      Yorumlar: {video.comment_count}
                    </span>
                  </div>
                </div>
              ))}
            {selectedVideo && (
              <VideoPlayer selectedVideo={selectedVideo} onClose={() => setSelectedVideo("")} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DoApprovalScreen
