import { combineReducers, configureStore } from "@reduxjs/toolkit"
import themeConfigSlice from "./themeConfigSlice"
import authSlice from "./auth/authSlice"
import getAllUserFiltersSlice from "./filters/user/getAllUserSlice"
import campaignFiltersSlice from "./filters/campaign/campaignFilterSlice"
import waitingApprovalUserFilterSlice from "./filters/user/waitingApprovalUserFilterSlice"
import doApprovalUserFilterSlice from "./filters/user/doApprovalUserFilterSlice"
import bannedUserFiltersSlice from "./filters/user/bannedUsersSlice"

const rootReducer = combineReducers({
  themeConfig: themeConfigSlice,
  auth: authSlice,
  campaignFilters: campaignFiltersSlice,
  waitingApprovalUserFilters: waitingApprovalUserFilterSlice,
  doApprovalUserFilters: doApprovalUserFilterSlice,
  bannedUserFilters: bannedUserFiltersSlice,
  getAllUserFilters: getAllUserFiltersSlice,
})

export default configureStore({
  reducer: rootReducer,
})

export type IRootState = ReturnType<typeof rootReducer>
