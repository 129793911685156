import React from "react"

export const columnConfig = ({ renderPostId }) => [
  {
    accessor: "id",
    title: "Id",
    sortable: true,
    render: renderPostId,
  },
  {
    accessor: "user.full_name",
    title: "IG Tam İsim",
  },
  {
    accessor: "user.is_private",
    title: "Kullanıcı Hesap Gizliliği",
    render: (value: any) => {
      const isPrivate = value === "true" ? true : false
      return isPrivate ? "True" : "False"
    },
  },
  {
    accessor: "user.username",
    title: "IG Kullanıcı Adı",
    render: (rowData) => {
      const username = rowData.user.username
      return (
        <a href={`https://www.instagram.com/${username}`} target="_blank" rel="noopener noreferrer">
          {username}
        </a>
      )
    },
  },
  {
    accessor: "s3_url",
    title: "S3 URL",
    render: (rowData) => {
      const s3_url: string = rowData.s3_url as string
      return (
        <a href={s3_url} target="_blank" rel="noopener noreferrer">
          {s3_url}
        </a>
      )
    },
  },

  {
    accessor: "owner.full_name",
    title: "Gönderi Sahibi Tam İsmi",
  },
  {
    accessor: "owner.is_private",
    title: "Gönderi Sahibi Hesap Gizliliği",
    render: (value: any) => {
      const isPrivate = value === "true" ? true : false
      return isPrivate ? "True" : "False"
    },
  },
  {
    accessor: "media_id",
    title: "Medya ID",
  },
  {
    accessor: "like_and_view_counts_disabled",
    title: "Beğeni ve Görüntüleme Sayısı Gizliliği",
    render: (value: any) => {
      const isDisabled = value === "true" ? true : false
      return isDisabled ? "True" : "False"
    },
  },
  {
    accessor: "commerciality_status",
    title: "Ticarilik Durumu",
    render: (value: any) => {
      const isStatus = value === "true" ? true : false
      return isStatus ? "True" : "False"
    },
  },
  {
    accessor: "like_count",
    title: "Beğeni Sayısı",
  },
  {
    accessor: "media_type",
    title: "Medya Türü",
  },

  {
    accessor: "is_paid_partnership",
    title: "Sponsorlu İçerik",
    render: (value: any) => {
      const isPaid = value === "true" ? true : false
      return isPaid ? "True" : "False"
    },
  },
  {
    accessor: "comment_count",
    title: "Yorum Sayısı",
  },
  {
    accessor: "caption_created_at",
    title: "Açıklama Oluşturulma Tarihi",
  },
  {
    accessor: "description",
    title: "Açıklama",
  },
]
