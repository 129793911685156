import React, { useEffect, Suspense } from "react"
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { selectToken, login } from "./redux/store/auth/authSlice"
import CommonLayout from "./components/initialComponents/CommonLayout"
import LoginScreen from "./pages/Auth/login/LoginScreen"
import RegisterScreen from "./pages/Auth/register/RegisterScreen"
import FindUser from "./pages/User/findUser/FindUser"
import BannedUsers from "./pages/User/bannedUsers/BannedUsers"
import WaitingApprovalUser from "./pages/User/waitingApprovalUser/WaitingApprovalUser"
import DoApprovalScreen from "./pages/User/doApprovalUsers/DoApprovalScreen"
import GetAllUsers from "./pages/User/getAllUsers/GetAllUsers"
import UserTaggedPostsScreen from "./pages/User/userTaggedPosts/UserTaggedPostsScreen"
import FindBrand from "./pages/Brand/findBrand/FindBrand"
import AllBrands from "./pages/Brand/allBrands/AllBrands"
import FindCampaign from "./pages/Campaign/findCampaign/FindCampaign"
import AllCampaign from "./pages/Campaign/allCampaigns/AllCampaign"
import Dashboard from "./pages/Dashboard/dashboard/Dashboard"
import HashtagSearch from "./pages/Dashboard/hashtagSearch/HashtagSearch"
import PopularBrands from "./pages/Dashboard/popularBrands/PopularBrands"
import WpMessages from "./pages/Dashboard/wpMessages/WpMessages"
import AllApplications from "./pages/Application/allApplications/AllApplications"
import APIdocsScreen from "./pages/ApiDoc/APIdocsScreen"

function App() {
  const token = useSelector(selectToken)
  const dispatch = useDispatch()

  useEffect(() => {
    const tokenString = localStorage.getItem("token")
    if (tokenString && !token) {
      dispatch(login({ token: tokenString }))
    }
  }, [dispatch, token])

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/auth/*"
          element={token ? <Navigate to="/dashboard/statistics" /> : <AuthLayout />}
        />
        <Route path="/*" element={token ? <MainLayout /> : <Navigate to="/auth/login" />} />
        <Route path="/user/*" element={token ? <UserLayout /> : <AuthLayout />} />
        <Route path="/brands/*" element={token ? <BrandsLayout /> : <AuthLayout />} />
        <Route path="/campaigns/*" element={token ? <CampaignsLayout /> : <AuthLayout />} />
        <Route path="/dashboard/*" element={token ? <DashboardLayout /> : <AuthLayout />} />
        <Route path="/applications/*" element={token ? <ApplicationsLayout /> : <AuthLayout />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App

function AuthLayout() {
  return (
    <Routes>
      <Route path="/login" element={<LoginScreen />} />
      <Route path="/register" element={<RegisterScreen />} />
    </Routes>
  )
}

function MainLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            <Route path="/api-docs" element={<APIdocsScreen />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}

function UserLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            <Route path="/find" element={<FindUser />} />
            <Route path="/find-waiting-approval" element={<WaitingApprovalUser />} />
            <Route path="/do-approval" element={<DoApprovalScreen />} />
            <Route path="/getall" element={<GetAllUsers />} />
            <Route path="/find/:id" element={<FindUser />} />
            <Route path="/user-tagged-posts" element={<UserTaggedPostsScreen />} />
            <Route path="/banned-users" element={<BannedUsers />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}

function BrandsLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            <Route path="/find" element={<FindBrand />} />
            <Route path="/find-all" element={<AllBrands />} />
            <Route path="/find/:id" element={<FindBrand />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}

function CampaignsLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            <Route path="/find/:_id" element={<FindCampaign />} />
            <Route path="/find-all" element={<AllBrands />} />
            <Route path="/find-all-campaigns" element={<AllCampaign />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}

function DashboardLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            <Route path="/statistics" element={<Dashboard />} />
            <Route path="/hashtag-search" element={<HashtagSearch />} />
            <Route path="/popular-brands" element={<PopularBrands />} />
            <Route path="/wp-messages" element={<WpMessages />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}

function ApplicationsLayout() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <CommonLayout>
          <Routes>
            <Route path="/all-applications" element={<AllApplications />} />
          </Routes>
        </CommonLayout>
      </Suspense>
    </>
  )
}
