import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBan,
  faCancel,
  faDollarSign,
  faEdit,
  faEye,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { Meeting, Stage } from "../../../../types/BrandData"
import { defaultStages } from "../../../../static/Brand"

export const columnConfig = ({
  handleSpamBrand,
  renderBrandId,
  editMode,
  handleDeleteNote,
  setNotes,
  notes,
  handleUpdateNote,
  toggleEditMode,
  setBrandNotes,
  editStage,
  handleBrandStage,
  setEditStage,
  handleDropdownClick,
  dropdownOpen,
  handleOptionChange,
  handleMeetingPlanned,
  handleInformationMailSent,
  handleDirectedToAgencyBasedWork,
  handleDemoCampaignCreated,
  handleBalanceLoaded,
  handleCampaignManagementContinues,
  handleCampaignCompleted,
  handlePersonalAccount,
}) => [
  {
    accessor: "brand",
    title: "Detaylar",
    sortable: false,
    render: ({ _id }: any) => (
      <a href={`/brands/find/${_id}`} target="_blank" rel="noopener noreferrer">
        <div className="text-center items-center mr-4">
          <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
        </div>
      </a>
    ),
  },
  { accessor: "id", title: "Id", render: renderBrandId },
  {
    accessor: "firstName",
    title: "İsim",
    render: ({ first_name, last_name }) => <div>{`${first_name} ${last_name}`}</div>,
  },
  {
    accessor: "balance",
    title: "Bakiye",
    render: ({ balance }: any) => (
      <div>
        {balance > 0 ? (
          <div>
            <p
              style={{
                color: "#009e1a",
                display: "inline-block",
                marginRight: "5px",
                fontWeight: "bold",
              }}
            >
              {balance}
            </p>
            <FontAwesomeIcon icon={faDollarSign} style={{ color: "#009e1a" }} />
          </div>
        ) : (
          <div>{balance}</div>
        )}
      </div>
    ),
  },
  { accessor: "brand_name", title: "Marka İsmi" },
  { accessor: "first_name", title: "İsim" },
  { accessor: "last_name", title: "Soyisim" },
  { accessor: "email", title: "Email" },
  { accessor: "phone", title: "Telefon" },
  {
    accessor: "created_at",
    title: "Oluşturulma Tarihi",
    render: ({ created_at }: any) => <div>{new Date(created_at).toLocaleDateString()}</div>,
  },
  {
    accessor: "is_spam",
    title: "Spam",
    render: ({ _id }: any) => (
      <div>
        <FontAwesomeIcon
          icon={faBan}
          style={{ color: "green", cursor: "pointer", marginLeft: "10px" }}
          onClick={() => handleSpamBrand(_id)}
        />
      </div>
    ),
  },
  {
    accessor: "notes",
    title: "Notlar",
    render: ({ _id, notes: initialNote }: { _id: string; notes: string[] }) => (
      <div className="text-center items-center mr-4">
        {editMode[_id] ? (
          <div>
            <ul>
              {initialNote
                .filter((note) => note !== "")
                .map((note, index) => (
                  <li key={index} className="flex items-center">
                    <p className="text-gray-600 mt-2">{note}</p>
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="mt-2"
                      style={{ color: "#005eff", marginLeft: "8px", cursor: "pointer" }}
                      onClick={() => handleDeleteNote(_id, index)}
                    />
                  </li>
                ))}
            </ul>
            <input
              type="text"
              onChange={(e) => setNotes(e.target.value)}
              className="text-input mt-2"
            />
            <FontAwesomeIcon
              icon={faSave}
              style={{ color: "green", cursor: "pointer", marginLeft: "10px" }}
              onClick={() => handleUpdateNote(_id, [...initialNote, notes])}
            />
          </div>
        ) : (
          <>
            <span>{initialNote}</span>
            <FontAwesomeIcon
              icon={faEdit}
              style={{ color: "#005eff", cursor: "pointer", marginLeft: "10px" }}
              onClick={() => {
                toggleEditMode(_id, initialNote)
                setBrandNotes(initialNote)
              }}
            />
          </>
        )}
      </div>
    ),
  },
  {
    accessor: "meetings",
    title: "Toplantılar",
    render: ({ meetings: initialMeeting }: { _id: string; meetings: Meeting[] }) => (
      <div className="text-center items-center mr-4">
        <ul>
          {initialMeeting &&
            initialMeeting.map((meeting, index) => (
              <li key={index} className="flex items-center">
                <p className="text-gray-600 mt-2">
                  {new Date(meeting.time).toLocaleString("tr-TR")}
                </p>
                <p className="text-gray-600 mt-2 ml-4 font-bold">{meeting.description}</p>
              </li>
            ))}
        </ul>
      </div>
    ),
  },
  {
    accessor: "stages",
    title: "Aşamalar",
    render: ({ _id, stages: initialStages }: { _id: string; stages: Stage[] }) => (
      <div className="text-center items-center mr-4">
        {initialStages && initialStages.length > 0 ? (
          <React.Fragment>
            {editStage ? (
              <div>
                <select
                  value={initialStages[0].stage}
                  onChange={(e) =>
                    handleBrandStage(_id, [
                      { stage: e.target.value, stage_time: new Date() },
                      ...initialStages,
                    ])
                  }
                  className="mt-2 p-1 border rounded"
                >
                  {defaultStages.map((stageOption, index) => (
                    <option key={index} value={stageOption}>
                      {stageOption}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon
                  icon={faCancel}
                  style={{ color: "#005eff", cursor: "pointer", marginLeft: "10px" }}
                  onClick={() => setEditStage(false)}
                />
              </div>
            ) : (
              <ul>
                <li className="flex-col" style={{ display: "flex", justifyContent: "flex-start" }}>
                  <div className="flex">
                    <p className="text-black-600 font-bold">{initialStages[0].stage}</p>
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{
                        color: "#005eff",
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                      onClick={() => setEditStage(true)}
                    />
                  </div>
                  <p className="text-gray-600 mr-40">
                    {new Date(initialStages[0].stage_time).toLocaleString("tr-TR")}
                  </p>
                </li>
              </ul>
            )}
          </React.Fragment>
        ) : (
          <div>
            <select
              value={"Select Stage"}
              onChange={(e) =>
                handleBrandStage(_id, [{ stage: e.target.value, stage_time: new Date() }])
              }
              className="mt-2 p-1 border rounded"
            >
              <option disabled>{"Select Stage"}</option>
              {defaultStages.map((stageOption, index) => (
                <option key={index} value={stageOption}>
                  {stageOption}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    ),
  },
  {
    accessor: "actions",
    title: "Marka İşlemleri",
    render: ({
      _id,
      meeting_planned,
      information_mail_sent,
      directed_to_agency_based_work,
      demo_campaign_created,
      balance_loaded,
      campaign_management_continues,
      campaign_completed,
      personal_account,
    }) => (
      <div>
        <div
          onClick={() => handleDropdownClick(_id)}
          className="dropdown-trigger cursor-pointer font-bold text-blue-600"
        >
          İşlemleri Aç/Kapat
        </div>
        {dropdownOpen[_id] && (
          <div className="dropdown-menu mt-2">
            <label>
              <input
                className="mr-2"
                type="checkbox"
                checked={meeting_planned}
                onChange={(e) => handleOptionChange(handleMeetingPlanned, _id, e.target.checked)}
              />
              Toplantı Planlandı
            </label>
            <label>
              <input
                className="mr-2"
                type="checkbox"
                checked={information_mail_sent}
                onChange={(e) =>
                  handleOptionChange(handleInformationMailSent, _id, e.target.checked)
                }
              />
              Bilgilendirme Maili İletildi
            </label>
            <label>
              <input
                className="mr-2"
                type="checkbox"
                checked={directed_to_agency_based_work}
                onChange={(e) =>
                  handleOptionChange(handleDirectedToAgencyBasedWork, _id, e.target.checked)
                }
              />
              Ajans Bazlı Çalışmaya Yönlendirildi
            </label>
            <label>
              <input
                className="mr-2"
                type="checkbox"
                checked={demo_campaign_created}
                onChange={(e) =>
                  handleOptionChange(handleDemoCampaignCreated, _id, e.target.checked)
                }
              />
              Demo Kampanya Oluşturdu
            </label>
            <label>
              <input
                className="mr-2"
                type="checkbox"
                checked={balance_loaded}
                onChange={(e) => handleOptionChange(handleBalanceLoaded, _id, e.target.checked)}
              />
              Bakiye Yüklemesi Yapıldı
            </label>
            <label>
              <input
                className="mr-2"
                type="checkbox"
                checked={campaign_management_continues}
                onChange={(e) =>
                  handleOptionChange(handleCampaignManagementContinues, _id, e.target.checked)
                }
              />
              Kampanya Yönetim Süreci Devam Ediyor
            </label>
            <label>
              <input
                className="mr-2"
                type="checkbox"
                checked={campaign_completed}
                onChange={(e) => handleOptionChange(handleCampaignCompleted, _id, e.target.checked)}
              />
              Kampanya Tamamlandı
            </label>
            <label>
              <input
                className="mr-2"
                type="checkbox"
                checked={personal_account}
                onChange={(e) => handleOptionChange(handlePersonalAccount, _id, e.target.checked)}
              />
              Kişisel Hesap
            </label>
          </div>
        )}
      </div>
    ),
  },
]
