import React, { useState, useEffect } from "react"
import { TAupdateApplication, TAuploadUserPayload } from "../services/applicationAPI"
import { TAfindCampaignById } from "../services/campaignsAPI"
import { applicationStateLabels } from "../pages/Campaign/findCampaign/FindCampaign"
import {
  generateAvailableStates,
  generateStatePayloads,
  handleStatePayload,
} from "../static/Application"

const checkBarter = async (campaign_id: string) => {
  try {
    const campaign = await TAfindCampaignById(campaign_id)
    if (campaign) {
      return campaign.barter
    }
  } catch (error) {
    console.error("Failed to get campaign", error)
  }
}

export const UpdateApplicationModal = ({
  isOpen,
  onClose,
  _id,
  campaign_id,
  state,
}: {
  isOpen: boolean
  onClose: any
  _id: string
  campaign_id: string
  state: string
}) => {
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [availableStates, setAvailableStates] = useState([""])
  const [newState, setNewState] = useState("")
  const [newStatePayload, setNewStatePayload] = useState("")
  const [view, setView] = useState("")
  const [reelsLikes, setReelsLikes] = useState("")
  const [reelsComments, setReelsComments] = useState("")
  const [reelsInteractions, setReelsInteractions] = useState("")
  const [reelsSavings, setReelsSavings] = useState("")
  const [reelsSharings, setReelsSharings] = useState("")
  const [selectedStatePayloads, setSelectedStatePayloads] = useState("")
  const [selectedSocialMedia, setSelectedSocialMedia] = useState("")
  const [imageLoading, setImageLoading] = useState(false)
  const [address, setAddress] = useState({
    contactName: "",
    contactPhone: "",
    city: "",
    country: "",
    address: "",
    details: "",
    zipCode: "",
    id: "",
    extra_information: "",
  })
  const [user_id, setUser_id] = useState("")
  const [statePayloadTypes, setStatePayloadTypes] = useState([""])

  useEffect(() => {
    const fetchCampaign = async () => {
      const barter = await checkBarter(campaign_id)
      if (barter === true) {
        generateAvailableStates(state, true, setAvailableStates)
      } else {
        generateAvailableStates(state, false, setAvailableStates)
      }
    }
    fetchCampaign()
  }, [state, campaign_id])

  useEffect(() => {
    generateStatePayloads(newState, setStatePayloadTypes)
  }, [newState])

  const handleSelectPhotoFromPC = async (type: string) => {
    try {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = "*"
      input.onchange = async (e) => {
        if (!(e.target instanceof HTMLInputElement) || !e.target.files) {
          return
        }
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append("user_payload", file)
        try {
          setImageLoading(true)
          const response = await TAuploadUserPayload(_id, formData, type)
          if (response.status !== 200) {
            throw new Error("Failed to upload brand logo")
          }
          setImageLoading(false)
          alert("User image uploaded successfully")
          setNewStatePayload(response.data.s3_url)
        } catch (error) {
          console.error("Error uploading photo:", error)
        }
      }
      input.click()
    } catch (error) {
      console.error("Error selecting photo:", error)
    }
  }

  const emptyBlankHandler = () => {
    if (newState === "") {
      setError(true)
      return false
    }
    setError(false)
    return true
  }

  const handleNewStateChange = () => {
    let statePayload = ""
    switch (selectedStatePayloads) {
      case "link":
        statePayload = `{"_id":"${user_id}","media_url":"${newStatePayload}"}`
        return statePayload
      case "img":
        if (selectedSocialMedia === "tiktok") {
          statePayload = `{"img":"${newStatePayload}","view":"${view}"}`
        } else if (selectedSocialMedia === "instagram") {
          statePayload = `{"img":"${newStatePayload}","view":"${view}","reelslikes":"${reelsLikes}","reelscomments":"${reelsComments}","reelsinteractions":"${reelsInteractions}","reelssavings":"${reelsSavings}","reelssharings":"${reelsSharings}"}`
        } else {
          statePayload = `{"img":"${newStatePayload}"}`
        }
        return statePayload
      case "review":
        statePayload = `{"review":"${newStatePayload}"}`
        return statePayload
      case "impressions":
        statePayload = `{"impressions":"${newStatePayload}"}`
        return statePayload
    }
  }

  const handleSubmitForm = async (e: any) => {
    e.preventDefault()
    if (!emptyBlankHandler()) {
      setIsLoading(false)
      return
    }
    const state_payload = handleNewStateChange()
    setIsLoading(true)
    address.contactPhone = `+${address.contactPhone}`
    let update = {
      state: newState,
      state_payload: state_payload,
      state_history: {
        state: state,
        state_payload: state_payload,
      },
      address: address,
    }
    try {
      await TAupdateApplication(update, _id)
      setIsLoading(false)
      onClose()
    } catch (error) {
      setIsLoading(false)
      console.error("Failed to update application", error)
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-lg overflow-y-auto h-1/2 max-w-md w-full space-y-4">
        {imageLoading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <>
            <h2 className="text-xl font-semibold mb-4">Başvuruyu Güncelle</h2>
            <form onSubmit={handleSubmitForm} className="space-y-4">
              <label className="block">
                <span className="text-gray-700">Yeni Durumu Seç</span>
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 mb-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  onChange={(e) => setNewState(e.target.value)}
                >
                  <option value="">Durum Seç</option>
                  {availableStates.map((state) => (
                    <option key={state} value={state}>
                      {applicationStateLabels[state] || state}
                    </option>
                  ))}
                </select>
              </label>

              {statePayloadTypes && (
                <label className="block">
                  <div>
                    <span className="text-gray-700">
                      Durum Yanıt Tipini Seç<span> (opsiyonel)</span>
                    </span>
                    <select
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 mb-3 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        setSelectedStatePayloads(e.target.value)
                      }}
                    >
                      <option value="" disabled hidden>
                        Yanıt Tipi Seç
                      </option>
                      {statePayloadTypes.map((statePayload) => (
                        <option key={statePayload} value={statePayload}>
                          {statePayload}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedStatePayloads &&
                    handleStatePayload(
                      selectedStatePayloads,
                      newState,
                      newStatePayload,
                      user_id,
                      setUser_id,
                      setNewStatePayload,
                      handleSelectPhotoFromPC,
                      setSelectedSocialMedia,
                      selectedSocialMedia,
                      view,
                      setView,
                      reelsLikes,
                      setReelsLikes,
                      reelsComments,
                      setReelsComments,
                      reelsInteractions,
                      setReelsInteractions,
                      reelsSavings,
                      setReelsSavings,
                      reelsSharings,
                      setReelsSharings,
                      address,
                      setAddress,
                    )}
                </label>
              )}

              {error && <p className="text-red-500">Please fill out all fields</p>}
              <div className="flex justify-end space-x-4">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="px-4 py-2 bg-blue-500 text-white hover:bg-blue-600 rounded"
                >
                  {isLoading ? "Yükleniyor..." : "Kaydet"}
                </button>
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded"
                >
                  Kapat
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  )
}
