import React from "react"
import { useEffect, useState } from "react"
import { DataTable, DataTableSortStatus } from "mantine-datatable"
import sortBy from "lodash/sortBy"
import DownloadCSVButton from "../../../components/DownloadCSVButton"
import { TAgetAllApplications } from "../../../services/applicationAPI"
import { columnConfig } from "./components/columns"
import { generateColumn } from "../../User/bannedUsers/components/columns"
import { handleSorting } from "../../User/getAllUsers/components/Functions"

const fetchData = async (
  page: number,
  perPage: number,
  params: any,
  sortBy: string,
  sortOrder: boolean,
) => {
  try {
    const response = await TAgetAllApplications(page, perPage, params, sortBy, sortOrder)
    if (response && Array.isArray(response.applications)) {
      const totalDocuments = response.totalDocuments
      const data = response.applications.map((item: any, index: any) => {
        return {
          id: index + 1,
          ...item,
        }
      })
      return { data, totalDocuments }
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

const AllApplications = () => {
  const [page, setPage] = useState(1)
  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [totalDocuments, setTotalDocuments] = useState(0)
  const [initialRecords, setInitialRecords] = useState(sortBy([], "id"))
  const sortStatus: DataTableSortStatus = { columnAccessor: "id", direction: "asc" }
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [sortByColumn, setSortByColumn] = useState("")
  const [sortOrder, setSortOrder] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  const handleFetchData = async () => {
    setLoading(true)
    const flattenFilters = Object.entries(filters).reduce((acc, [key, filter]) => {
      if (key === "insta_username" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "tiktok_username" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "campaign" && typeof filter === "string") {
        acc[key] = filter
      } else if (key === "user" && typeof filter === "string") {
        acc[key] = filter
      }

      return acc
    }, {} as { [key: string]: string })

    const params = new URLSearchParams(flattenFilters)
    try {
      const data = await fetchData(page, pageSize, params, sortByColumn, sortOrder)
      if (data !== undefined) {
        setInitialRecords(data.data)
        setTotalDocuments(data.totalDocuments)
      } else {
        setError("No data found")
      }
      setLoading(false)
    } catch (error) {
      setError(`Error fetching data, ${error}`)
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [page, pageSize, sortByColumn, sortOrder])

  const renderStatePayload = (application: any) => {
    const toggleExpandedRow = () => {
      setIsExpanded(!isExpanded)
    }
    if (isExpanded) {
      return (
        <div>
          {application.state_payload}
          <button
            type="button"
            onClick={toggleExpandedRow}
            className="text-blue-500 hover:underline"
          >
            Less
          </button>
        </div>
      )
    } else if (application.state_payload?.length > 20) {
      return (
        <div>
          {`${application.state_payload.slice(0, 20)}... `}
          <button
            type="button"
            onClick={toggleExpandedRow}
            className="text-blue-500 hover:underline"
          >
            More
          </button>
        </div>
      )
    } else {
      return <div>{application.state_payload}</div>
    }
  }

  const defaultState = {
    insta_username: "",
    tiktok_username: "",
    campaign: "",
    user: "",
  }
  const [filters, setFilters] = useState(defaultState)

  const updateFilter = (key: string, value: string) => {
    if (key === "insta_username") {
      setFilters((prev) => ({ ...prev, [key]: value as string }))
    } else if (key === "tiktok_username") {
      setFilters((prev) => ({ ...prev, [key]: value as string }))
    } else if (key === "campaign") {
      setFilters((prev) => ({ ...prev, [key]: value as string }))
    } else if (key === "user") {
      setFilters((prev) => ({ ...prev, [key]: value as string }))
    }
  }

  const columns = columnConfig({
    renderStatePayload,
  }).map((col) => generateColumn(col.accessor, col.title, col.sortable, col.render))

  return (
    <div className="panel">
      {error && <div className="text-red-500 text-center">{error}</div>}
      <div className="flex w-full justify-between flex-end">
        <div className="flex gap-4">
          <div>
            <input
              type="text"
              value={filters["user"]}
              onChange={(e) => {
                updateFilter("user", e.target.value)
              }}
              className="form-input w-full mb-2"
              placeholder={"Kullanıcı ID"}
            />{" "}
            <input
              type="text"
              value={filters["campaign"]}
              onChange={(e) => {
                updateFilter("campaign", e.target.value)
              }}
              className="form-input w-full mb-2"
              placeholder={"Kampanya ID"}
            />
          </div>
          <div>
            <input
              type="text"
              value={filters["insta_username"]}
              onChange={(e) => {
                updateFilter("insta_username", e.target.value)
              }}
              className="form-input w-full mb-2"
              placeholder={"IG kullanıcı adı"}
            />
            <input
              type="text"
              value={filters["tiktok_username"]}
              onChange={(e) => {
                updateFilter("tiktok_username", e.target.value)
              }}
              className="form-input w-full mb-2"
              placeholder={"TT kullanıcı adı"}
            />
          </div>
        </div>
        <div className="flex flex-row ml-auto justify-end text-center w-1/3 mb-4 mr-2">
          <button
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            onClick={() => handleFetchData()}
          >
            Kullanıcı Ara
          </button>
          <DownloadCSVButton
            className=" inline-flex items-center justify-center ml-2 px-2 py-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 "
            userData={initialRecords}
          />
        </div>
      </div>

      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={initialRecords}
            columns={columns}
            totalRecords={totalDocuments}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={(e) =>
              handleSorting(e.columnAccessor, setSortByColumn, setSortOrder)
            }
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
          />
        )}
      </div>
    </div>
  )
}

export default AllApplications
