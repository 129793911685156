import React from "react"
import { Campaign } from "../types/CampaignData"

interface CampaignCardProps {
  campaign: Campaign
}

const CampaignCard: React.FC<CampaignCardProps> = ({ campaign }) => {
  return (
    <div
      className="flex-shrink-0 w-60 p-4 bg-white shadow-md rounded-lg border border-gray-200 hover:shadow-xl transition-shadow duration-300 cursor-pointer"
      onClick={() => window.open(`/campaigns/find/${campaign._id}`, "_blank")}
    >
      <div>
        <img
          src={campaign.cover_photo || "https://via.placeholder.com/150"}
          alt={campaign.name}
          className="w-16 h-16 rounded-full object-cover mx-auto mb-4"
        />
        <h3 className="text-center text-lg font-semibold">{campaign.name}</h3>
        <p className="text-center text-lg font-semibold">
          {campaign.platform} |{" "}
          <span className="text-center text-sm text-gray-500">
            {campaign.visibility ? "Yayında" : "Yayında Değil"}
          </span>
        </p>
        <p className="text-center text-lg font-semibold">
          Barter:
          <span className="text-center text-sm text-gray-500">
            {campaign.barter ? "Evet" : "Değil"}
          </span>
        </p>
        <p className="text-center text-lg font-semibold">
          Bütçe:
          <span className="text-center text-sm text-gray-500">
            {campaign.estimated_budget} {campaign.currency}
          </span>
        </p>
        <p className="text-center text-lg">
          Kampanya Aralığı:{" "}
          <span className="text-center text-sm text-gray-500">
            {campaign?.campaign_start ? new Date(campaign.campaign_start).toLocaleDateString() : ""}{" "}
            - {campaign?.campaign_end ? new Date(campaign.campaign_end).toLocaleDateString() : ""}
          </span>
        </p>
      </div>
    </div>
  )
}

export default CampaignCard
