import React from "react"

interface MinMaxInputProps {
  value: any
  setValue: (value: any) => void
  placeholder: string
}

const MinMaxInput: React.FC<MinMaxInputProps> = ({ value, setValue, placeholder }) => {
  return (
    <div className="relative w-full rounded-2xl">
      <input
        id={placeholder}
        type="num"
        value={value}
        onChange={setValue}
        className={
          "form-input bg-gray-100 rounded-2xl pt-6 mb-1 w-full focus:ring-0 focus:outline-none transition-all duration-200 peer"
        }
      />
      <label
        htmlFor={placeholder}
        className={`absolute left-4 top-4 transition-all duration-200 transform pointer-events-none ${
          value ? "-translate-y-3 text-xs text-gray-600" : "text-base text-gray-400"
        } peer-focus:-translate-y-3 peer-focus:text-xs peer-focus:text-gray-600`}
      >
        {placeholder}
      </label>
    </div>
  )
}

export default MinMaxInput
