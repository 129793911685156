import React, { useState } from "react"
import ProfileDetailModal from "./ProfileDetailModal"
import { TACreateApplication } from "../services/applicationAPI"

interface UserCardProps {
  user: any
  campaign_id: string
  campaign_type: string
}

const UserCard: React.FC<UserCardProps> = ({ user, campaign_id, campaign_type }) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showPriceModal, setShowPriceModal] = useState(false)
  const [priceUser, setPriceUser] = useState(0)
  const [priceBrand, setPriceBrand] = useState(0)

  const handleCreateApplication = async () => {
    setIsLoading(true)
    setShowPriceModal(false)
    const applicationUser = {
      user_id: user._id,
      insta_username: user.insta.username,
      age: user.age,
      gender: user.gender,
      verification: user.verification,
      state: "invite_new",
      school_type: user.school_type,
      school_name: user.school_name,
      city: user.city,
      country: user.country,
      language: user.language,
      currency: user.currency,
      job: user.job,
      birthday: user.birthday,
      price_user: priceUser,
      price_brand: priceBrand,
      tiktok_username: user.tiktok.username,
      insta: user.insta,
      tiktok: user.tiktok,
    }
    try {
      const response = await TACreateApplication(
        applicationUser,
        campaign_id,
        "invite_new",
        {},
        campaign_type,
      )
      console.log("Application created", response)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  return (
    <>
      <div className="flex-shrink-0 w-60 p-4 bg-white shadow-md rounded-lg border border-gray-200 hover:shadow-xl transition-shadow duration-300">
        <div onClick={() => setShowModal(true)}>
          <img
            src={user.insta.profile_pic || "https://via.placeholder.com/150"}
            alt={user.name}
            className="w-16 h-16 rounded-full object-cover mx-auto mb-4"
          />
          <h3 className="text-center text-lg font-semibold">{user.insta.full_name}</h3>
          <p className="text-center text-sm text-gray-500">
            {user.country} | {user.city}
          </p>
          <div className="flex flex-col">
            <p className="text-center text-sm text-gray-500">
              Yaş: {user.age > 65 ? "65" : user.age}
            </p>
            <p className="text-center text-sm text-gray-500">IG Takipçi: {user.insta.followers}</p>
            <p className="text-center text-sm text-gray-500">TT Takipçi: {user.tiktok.followers}</p>
          </div>
        </div>
        {!isLoading ? (
          <div className="flex items-center justify-center mt-4">
            <button
              className="text-blue-600 font-semibold cursor-pointer"
              onClick={() => setShowPriceModal(true)}
            >
              Başvuranlara ekle
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center mt-4">
            <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        )}
      </div>
      <ProfileDetailModal onClose={() => setShowModal(false)} isOpen={showModal} data={user} />

      {showPriceModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg w-80">
            <h2 className="text-lg font-semibold mb-4">Tutarı Güncelle</h2>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Price User</label>
              <input
                type="number"
                value={priceUser}
                onChange={(e) => setPriceUser(Number(e.target.value))}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Price Brand</label>
              <input
                type="number"
                value={priceBrand}
                onChange={(e) => setPriceBrand(Number(e.target.value))}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => setShowPriceModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded mr-2"
              >
                Kapat
              </button>
              <button
                onClick={handleCreateApplication}
                className="px-4 py-2 bg-blue-600 text-white rounded"
              >
                Onayla
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UserCard
