import React from "react"
import { numFixer } from "./Functions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBan,
  faEdit,
  faEye,
  faMars,
  faStar,
  faTimes,
  faVenus,
  faCrown,
} from "@fortawesome/free-solid-svg-icons"
import { BrandSectorsData } from "../../../../static/BrandSectors"

export const columnConfig = ({
  isAllSelected,
  isIndeterminate,
  handleSelectAll,
  handleSelectRow,
  selectedRecords,
  removeVerification,
  setSelectedUser,
  setIsOpenDetailModal,
  renderUserId,
  addVerification,
  banUser,
  setIsEditCategoryModalOpen,
  setIsEditSubCategoryModalOpen,
  handlePrioritize,
}) => [
  {
    accessor: "select",
    title: (
      <input
        type="checkbox"
        checked={isAllSelected}
        ref={(input) => {
          if (input) {
            input.indeterminate = isIndeterminate
          }
        }}
        onChange={(e) => handleSelectAll(e.target.checked)}
      />
    ),
    sortable: false,
    render: ({ _id }) => (
      <input
        type="checkbox"
        checked={selectedRecords.includes(_id)}
        onChange={() => handleSelectRow(_id)}
      />
    ),
  },
  {
    accessor: "id",
    title: "Id",
    sortable: true,
    render: (record) => (
      <div
        onClick={() => {
          setSelectedUser(record)
          setIsOpenDetailModal(true)
        }}
        style={{ cursor: "pointer" }}
      >
        {renderUserId(record.id - 1)}
      </div>
    ),
  },
  {
    accessor: "verification",
    title: "Onay Durumu",
    sortable: true,
    render: ({ verification, _id }: any) => (
      <div className="text-center items-center">
        {verification ? (
          <FontAwesomeIcon
            icon={faStar}
            style={{ color: "#ffba00", cursor: "pointer" }}
            onClick={() => removeVerification(_id)}
          />
        ) : (
          <FontAwesomeIcon
            icon={faTimes}
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => addVerification(_id)}
          />
        )}
      </div>
    ),
  },
  {
    accessor: "prioritized",
    title: "Öncelik Durumu",
    sortable: true,
    render: ({ prioritized, _id }: any) => (
      <div className="text-center items-center">
        {prioritized ? (
          <FontAwesomeIcon
            icon={faCrown}
            style={{ color: "#ffba00", cursor: "pointer" }}
            onClick={() => handlePrioritize(_id, false)}
          />
        ) : (
          <FontAwesomeIcon
            icon={faTimes}
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handlePrioritize(_id, true)}
          />
        )}
      </div>
    ),
  },
  {
    accessor: "details",
    title: "Detaylar",
    sortable: true,
    render: ({ _id }: any) => (
      <a href={`/user/find/${_id}`} target="_blank" rel="noopener noreferrer">
        <div className="text-center items-center">
          <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
        </div>
      </a>
    ),
  },
  {
    accessor: "ban_user",
    title: "Kullanıcıyı Banla",
    sortable: true,
    render: ({ _id }) => (
      <div className="text-center items-center">
        <FontAwesomeIcon
          icon={faBan}
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => banUser(_id)}
        />
      </div>
    ),
  },
  {
    accessor: "firstName",
    title: "İsim",
    sortable: true,
    render: (record) => (
      <div
        onClick={() => {
          setSelectedUser(record)
          setIsOpenDetailModal(true)
        }}
        style={{ cursor: "pointer" }}
      >
        {record.name}
      </div>
    ),
  },
  {
    accessor: "phone",
    title: "Telefon",
    sortable: true,
    render: (record) => {
      return record.phone && record.phone.length < 19 ? (
        <div
          className="flex w-full"
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {record.phone}
        </div>
      ) : (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >{`${record.phone?.slice(0, 19)}..`}</div>
      )
    },
  },
  {
    accessor: "age",
    title: "Yaş",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {record.age > 65 ? "65" : record.age}
        </div>
      )
    },
  },
  {
    accessor: "job",
    title: "Meslek",
    sortable: true,
    render: (record) => {
      return (
        <div
          className="flex w-full"
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {record.job}
        </div>
      )
    },
  },
  {
    accessor: "category",
    title: "Kategori",
    sortable: true,
    render: ({ category, _id }) => {
      const matchedCategory = BrandSectorsData.find((item) => item.value === category)

      return (
        <div className="flex flex-row items-center gap-2">
          {matchedCategory ? (
            <>
              <matchedCategory.image className="h-5 w-5 text-blue-500" />
              <span>{matchedCategory.name}</span>
            </>
          ) : (
            <span>Kategori Yok</span>
          )}
          <FontAwesomeIcon
            icon={faEdit}
            style={{ color: "#005eff", cursor: "pointer" }}
            title="Edit Category"
            onClick={() => setIsEditCategoryModalOpen({ modal: true, _id })}
          />
        </div>
      )
    },
  },
  {
    accessor: "sub_category",
    title: "Alt Kategori",
    sortable: true,
    render: ({ sub_category, _id }) => (
      <div className="flex items-center gap-2">
        <span>
          {sub_category && sub_category.length > 0
            ? `${sub_category.slice(0, 2).join(", ")}${sub_category.length > 2 ? "..." : ""}`
            : "Alt Kategori Yok"}
        </span>
        <FontAwesomeIcon
          icon={faEdit}
          style={{ color: "#005eff", cursor: "pointer" }}
          title="Edit Sub Category"
          onClick={() => setIsEditSubCategoryModalOpen({ modal: true, _id })}
        />
      </div>
    ),
  },
  {
    accessor: "insta.full_name",
    title: "Insta Tam Adı",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {record.insta.full_name}
        </div>
      )
    },
  },
  {
    accessor: "insta.username",
    title: "Insta Kullanıcı Adı",
    sortable: true,
    render: ({ insta }: any) => (
      <a
        href={`https://www.instagram.com/${insta?.username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="text-center items-center">
          <span>{insta.username}</span>
        </div>
      </a>
    ),
  },
  {
    accessor: "tiktok.username",
    title: "TikTok Kullanıcı Adı",
    sortable: true,
    render: ({ tiktok }: any) => (
      <a
        href={`https://www.tiktok.com/@${tiktok?.username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="text-center items-center">
          <span>{tiktok?.username}</span>
        </div>
      </a>
    ),
  },
  {
    accessor: "gender",
    title: "Cinsiyet",
    sortable: true,
    render: (record) => (
      <div
        className="text-center items-center"
        onClick={() => {
          setSelectedUser(record)
          setIsOpenDetailModal(true)
        }}
      >
        {record.gender === "male" ? (
          <FontAwesomeIcon icon={faMars} style={{ color: "#005eff" }} />
        ) : record.gender === "female" ? (
          <FontAwesomeIcon icon={faVenus} style={{ color: "#ff00dd" }} />
        ) : null}
      </div>
    ),
  },
  {
    accessor: "country",
    title: "Ülke",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {record.country}
        </div>
      )
    },
  },
  {
    accessor: "keywordCount",
    title: "Sıklık",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {record.keywordCount}
        </div>
      )
    },
  },
  {
    accessor: "insta.followers",
    title: "Insta Takipçi",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {numFixer(record.insta.followers)}
        </div>
      )
    },
  },
  {
    accessor: "insta.following",
    title: "Insta Takip",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {numFixer(record.insta.following)}
        </div>
      )
    },
  },
  {
    accessor: "insta.average_like",
    title: "Insta Ortalama Beğeni",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {numFixer(record.insta.average_like)}
        </div>
      )
    },
  },
  {
    accessor: "insta.average_reel_view",
    title: "Insta Ortalama Reel İzlenme",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {numFixer(record.insta?.average_reel_view)}
        </div>
      )
    },
  },
  {
    accessor: "insta.face_count",
    title: "Insta Yüz Sayısı",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {numFixer(record.insta?.face_count)}
        </div>
      )
    },
  },
  {
    accessor: "insta.engagement_rate",
    title: "Insta Etkileşim Oranı",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {numFixer(record.insta.engagement_rate)}
        </div>
      )
    },
  },
  {
    accessor: "insta.post_number",
    title: "Insta Gönderi Sayısı",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {record.insta.post_number}
        </div>
      )
    },
  },
  {
    accessor: "tiktok.followers",
    title: "TikTok Takipçi",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {numFixer(record.tiktok?.followers)}
        </div>
      )
    },
  },
  {
    accessor: "tiktok.following",
    title: "TikTok Takip",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {numFixer(record.tiktok?.following)}
        </div>
      )
    },
  },
  {
    accessor: "tiktok.tiktok_average_like",
    title: "TikTok Ortalama Beğeni",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {numFixer(record.tiktok?.tiktok_average_like)}
        </div>
      )
    },
  },
  {
    accessor: "tiktok.tiktok_engagement_rate",
    title: "TikTok Etkileşim Oranı",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {numFixer(record.tiktok?.tiktok_engagement_rate)}
        </div>
      )
    },
  },
  {
    accessor: "tiktok.hearts",
    title: "TikTok Kalp Sayısı",
    sortable: true,
    render: (record) => {
      return (
        <div
          onClick={() => {
            setSelectedUser(record)
            setIsOpenDetailModal(true)
          }}
        >
          {numFixer(record.tiktok?.hearts)}
        </div>
      )
    },
  },
]
