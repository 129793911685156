import React from "react"
import convertToCSV from "../utils/WaitingApprovalUserCSV"

const DownloadCSVButton = ({ className, userData }: any) => {
  const handleCSVDownload = () => {
    const csvData = convertToCSV(userData)
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = URL.createObjectURL(blob)

    const a = document.createElement("a")
    a.href = url
    a.download = "list.csv"
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

    URL.revokeObjectURL(url)
  }

  return (
    <div className={className}>
      <button onClick={handleCSVDownload}>CSV olarak indir</button>
    </div>
  )
}

export default DownloadCSVButton
