import KeywordData from "../../../../JSON/KEYWORDS.json"

export const phoneNumberFixer = (phoneNumber: string) => {
  const fixedPhoneNumber = phoneNumber.slice(0, 13)
  return fixedPhoneNumber
}

export const numFixer = (num: number) => {
  if (!num) {
    return 0
  }
  const roundedNumber = Math.round(num * 10) / 10
  return roundedNumber
}

export const getFilteredKeywords = (keywords: string, limit: number = 4): string[] => {
  const autoCompleteKeywords = keywords.split(",").map((keyword) => keyword.trim())
  const lastKeyword = autoCompleteKeywords[autoCompleteKeywords.length - 1]

  const uniqueKeywords = [...new Set(KeywordData.keywords)]
  const filteredKeywords = uniqueKeywords
    .filter((keyword: string) => keyword.toLowerCase().startsWith(lastKeyword.toLowerCase()))
    .slice(0, limit)

  return autoCompleteKeywords.length === 0 ? [] : filteredKeywords
}

export const handleKeywordInputChange = (
  inputValue: string,
  setKeywords: (value: string) => void,
  setIsDropdownOpen: (value: boolean) => void,
) => {
  const inputKeywords = inputValue
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
  setKeywords(inputKeywords)

  setIsDropdownOpen(inputKeywords.length > 0)
}

export const handleSorting = (sortyBy: string, setSortByColumn: any, setSortOrder: any) => {
  setSortByColumn(sortyBy)
  setSortOrder((prevOrder) => !prevOrder)
}

export const handleInputChangeCountry = (
  e: any,
  handleFilterChange: any,
  setCountry: any,
  countries: any,
  setAutofillCountries: any,
) => {
  const value = e.target.value
  handleFilterChange("country", value)
  setCountry(value)

  if (value.length > 0) {
    const filteredCountries = countries
      .filter((country: any) => country.name.toLowerCase().startsWith(value.toLowerCase()))
      .map((country: any) => country.name)
    setAutofillCountries(filteredCountries)
  } else {
    setAutofillCountries([])
  }
}

export const handleCountrySuggestionClick = (
  key: any,
  selectedCountry: any,
  handleFilterChange: any,
  setCountry: any,
  setAutofillCountries: any,
) => {
  handleFilterChange(key, selectedCountry)
  setCountry(selectedCountry)
  setAutofillCountries([])
}

export const handleInputChangeCity = (
  value: any,
  handleFilterChange: any,
  setCity: any,
  cities: any,
  setAutofillCities: any,
) => {
  handleFilterChange("city", "value", value)
  setCity(value)

  if (value.length > 0) {
    const filteredCities = cities
      .filter((city) => city.name.toLowerCase().startsWith(value.toLowerCase()))
      .map((city) => city.name)
    setAutofillCities(filteredCities)
  } else {
    setAutofillCities([])
  }
}
export const handleCitySuggestionClick = (
  key: any,
  selectedCity: any,
  handleFilterChange: any,
  setCity: any,
  setAutofillCities: any,
) => {
  handleFilterChange(key, "value", selectedCity)
  setCity(selectedCity)
  setAutofillCities([])
}

export const handleInputChangeJob = (
  e: any,
  handleFilterChange: any,
  setJob: any,
  jobs: any,
  setAutofillJobs: any,
) => {
  const value = e.target.value
  handleFilterChange("job", "value", value)
  setJob(value)

  if (value.length > 0) {
    const filteredJobs = jobs
      .filter((job) => job.name.toLowerCase().startsWith(value.toLowerCase()))
      .map((job) => job.name)
    setAutofillJobs(filteredJobs)
  } else {
    setAutofillJobs([])
  }
}
export const handleJobSuggestionClick = (
  key: any,
  selectedJob: any,
  handleFilterChange: any,
  setJob: any,
  setAutofillJobs: any,
) => {
  handleFilterChange(key, "value", selectedJob)
  setJob(selectedJob)
  setAutofillJobs([])
}
