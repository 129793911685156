import React from "react"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { TAbannedUser, TAremoveBan, TAremoveBanManyUsers } from "../../../services/userAPI"
import { User } from "../../../types/UserData"
import { DataTable, DataTableSortStatus } from "mantine-datatable"
import sortBy from "lodash/sortBy"
import { FilterValue } from "../../../types/FilterData"
import DownloadCSVButton from "../../../components/DownloadCSVButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUnlock } from "@fortawesome/free-solid-svg-icons"
import { selectBannedUserFilters } from "../../../redux/store/filters/user/bannedUsersSlice"
import { columnConfig, generateColumn } from "./components/columns"
import Filters from "./components/Filters"
import { handleSorting } from "../getAllUsers/components/Functions"

const fetchData = async (
  params: any,
  page: number,
  perPage: number,
  sortBy: string,
  sortOrder: boolean,
) => {
  try {
    const response = await TAbannedUser(params, page, perPage, sortBy, sortOrder)
    if (response && Array.isArray(response.users)) {
      const totalRecords = response.totalDocuments
      const data = response.users.map((item: any, index: any) => {
        return {
          id: index + 1,
          ...item,
        }
      })
      return { data, totalRecords }
    }
  } catch (error: any) {
    throw new Error(error)
  }
}

const BannedUsers = () => {
  const approvedUserFilters = useSelector(selectBannedUserFilters)

  const PAGE_SIZES = [10, 20, 30, 50, 100]
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(PAGE_SIZES[2])
  const [totalRecords, setTotalRecords] = useState(0)
  const [initialRecords, setInitialRecords] = useState(sortBy([] as User[], "id"))
  const sortStatus: DataTableSortStatus = { columnAccessor: "id", direction: "asc" }
  const [sortByColumn, setSortByColumn] = useState("")
  const [sortOrder, setSortOrder] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [selectedRecords, setSelectedRecords] = useState<any>([])
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const handleFetchData = async () => {
    setLoading(true)
    const flattenFilters = Object.entries(approvedUserFilters).reduce((acc, [key, filter]) => {
      const simpleKeys = [
        "gender",
        "verification",
        "warnings",
        "platform",
        "is_spam",
        "unvisible",
        "last_login",
        "frequency",
        "country",
        "city",
        "job",
        "insta_username",
        "tiktok_username",
      ]

      if (simpleKeys.includes(key) && typeof filter === "string") {
        acc[key] = filter
      } else if (filter && typeof filter === "object") {
        const { min, max } = filter as FilterValue
        if (min) {
          acc[`min_${key}`] = min
        }
        if (max) {
          acc[`max_${key}`] = max
        }
      }

      return acc
    }, {} as { [key: string]: string })

    const params = new URLSearchParams(flattenFilters)
    const keywords = (approvedUserFilters.keywords as string[]).map(
      (keyword) => keyword.charAt(0).toUpperCase() + keyword.slice(1),
    )
    keywords.forEach((keywords) => {
      params.append("keywords", keywords)
    })
    const hobbies = (approvedUserFilters.hobbies as string[]).map(
      (hobby) => hobby.charAt(0) + hobby.slice(1),
    )
    hobbies.forEach((hobbies) => {
      params.append("hobbies", hobbies)
    })
    try {
      const data: any = await fetchData(params, page, pageSize, sortByColumn, sortOrder)
      if (data !== undefined) {
        setInitialRecords(data.data)
        setTotalRecords(data.totalRecords)
      } else {
        setError("No data found")
      }
      setLoading(false)
    } catch (error) {
      setError("No data found")
    }
  }

  useEffect(() => {
    handleFetchData()
  }, [page, pageSize, sortByColumn, sortOrder])

  const renderBrandId = (record: any, index: number) => {
    const itemsPerPage = page * pageSize
    const recordIndex = itemsPerPage + index
    const brandId = recordIndex - pageSize + 1
    return <div>{brandId}</div>
  }

  const removeVerification = async (id: any) => {
    try {
      const response = await TAremoveBan(id)
      if (response) {
        setInitialRecords((prevUsers) => prevUsers.filter((user) => user._id !== id))
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allIds = initialRecords.map((record) => record._id)
      setSelectedRecords(allIds)
    } else {
      setSelectedRecords([])
    }
  }

  const handleSelectRow = (id) => {
    setSelectedRecords((prevSelected: any) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id],
    )
  }

  const isAllSelected = selectedRecords.length === initialRecords.length
  const isIndeterminate =
    selectedRecords.length > 0 && selectedRecords.length < initialRecords.length

  const handleBulkDelete = async () => {
    try {
      const response = await TAremoveBanManyUsers(selectedRecords)
      if (response) {
        const updatedRecords = initialRecords.filter(
          (record) => !selectedRecords.includes(record._id),
        )
        setInitialRecords(updatedRecords)
        setSelectedRecords([])
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const columns = columnConfig({
    isAllSelected,
    isIndeterminate,
    handleSelectAll,
    handleSelectRow,
    selectedRecords,
    removeVerification,
    renderBrandId,
  }).map((col) => generateColumn(col.accessor, col.title, col.sortable, col.render))

  return (
    <div className="panel">
      <Filters
        isOpen={isFilterOpen}
        onClose={() => setIsFilterOpen(false)}
        handleFetchData={handleFetchData}
      />
      <div className="flex items-center">
        <div
          className="cursor-pointer inline-flex items-center justify-center p-2 mb-4 border border-transparent text-sm font-medium rounded-3xl text-white bg-gray-400 hover:bg-gray-500"
          onClick={() => handleBulkDelete()}
        >
          <span>Seçilenlerin Banını Kaldır</span>
          <FontAwesomeIcon className="ml-2" icon={faUnlock} style={{ color: "green" }} />
        </div>
        <div
          className="flex w-1/10 bg-gray-100 ml-4 items-center border border-gray-300 rounded-3xl mb-4 p-2 cursor-pointer"
          onClick={() => setIsFilterOpen(true)}
        >
          Filtrele
        </div>
        <div className="flex flex-row justify-end text-center w-1/3 mb-6 mt-2 ml-auto mr-2">
          <button
            className=" inline-flex items-center justify-center ml-2 p-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-3xl text-white bg-blue-600 hover:bg-blue-700"
            onClick={() => handleFetchData()}
          >
            Kullanıcıları Ara
          </button>
          <DownloadCSVButton
            className=" inline-flex items-center justify-center ml-2 px-2 mt-6 mb-2 border border-transparent text-sm font-medium rounded-3xl text-white bg-green-600 hover:bg-green-700"
            userData={initialRecords}
          />
        </div>
      </div>
      <div className="datatables">
        {loading ? (
          <div className="flex items-center justify-center h-40">
            <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-pink-600"></div>
          </div>
        ) : (
          <DataTable
            highlightOnHover
            className="whitespace-nowrap table-hover"
            records={initialRecords}
            columns={columns}
            totalRecords={totalRecords}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
            sortStatus={sortStatus}
            onSortStatusChange={(e) =>
              handleSorting(e.columnAccessor, setSortByColumn, setSortOrder)
            }
            minHeight={200}
            paginationText={({ from, to, totalRecords }) =>
              `Showing  ${from} to ${to} of ${totalRecords} entries`
            }
          />
        )}
      </div>
    </div>
  )
}

export default BannedUsers
