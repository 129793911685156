import React from "react"
import { faVenus, faMars, faEye, faUnlock } from "@fortawesome/free-solid-svg-icons"
import { numFixer } from "../../getAllUsers/components/Functions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const columnConfig = ({
  isAllSelected,
  isIndeterminate,
  handleSelectAll,
  handleSelectRow,
  selectedRecords,
  removeVerification,
  renderBrandId,
}) => [
  {
    accessor: "select",
    title: (
      <input
        type="checkbox"
        checked={isAllSelected}
        ref={(input) => {
          if (input) {
            input.indeterminate = isIndeterminate
          }
        }}
        onChange={(e) => handleSelectAll(e.target.checked)}
      />
    ),
    sortable: false,
    render: ({ _id }) => (
      <input
        type="checkbox"
        checked={selectedRecords.includes(_id)}
        onChange={() => handleSelectRow(_id)}
      />
    ),
  },
  { accessor: "id", title: "Id", sortable: true, render: renderBrandId },
  {
    accessor: "details",
    title: "Detaylar",
    sortable: false,
    render: ({ _id }: any) => (
      <a href={`/user/find/${_id}`} target="_blank" rel="noopener noreferrer">
        <div className="text-center items-center mr-4">
          <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
        </div>
      </a>
    ),
  },
  {
    accessor: "ban_user",
    title: "Kullanıcı Banı Kaldır",
    sortable: true,
    render: ({ _id }) => (
      <div className="text-center items-center">
        <FontAwesomeIcon
          icon={faUnlock}
          style={{ color: "green", cursor: "pointer" }}
          onClick={() => removeVerification(_id)}
        />
      </div>
    ),
  },
  {
    accessor: "firstName",
    title: "İsim",
    sortable: true,
    render: ({ name }) => <div>{name}</div>,
  },
  { accessor: "email", title: "Email", sortable: true },
  {
    accessor: "age",
    title: "Yaş",
    sortable: true,
    render: ({ age }) => <div>{age > 65 ? "65" : age}</div>,
  },
  {
    accessor: "gender",
    title: "Cinsiyet",
    sortable: false,
    render: ({ gender }) => (
      <div className="text-center items-center">
        {gender === "male" ? (
          <FontAwesomeIcon icon={faMars} style={{ color: "#005eff" }} />
        ) : (
          <FontAwesomeIcon icon={faVenus} style={{ color: "#ff00dd" }} />
        )}
      </div>
    ),
  },
  { accessor: "insta.full_name", title: "IG Tam İsim", sortable: true },
  {
    accessor: "insta.username",
    title: "Insta Kullanıcı Adı",
    sortable: true,
    render: ({ insta }: any) => (
      <a
        href={`https://www.instagram.com/${insta.username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="text-center items-center">
          <span>{insta.username}</span>
        </div>
      </a>
    ),
  },
  {
    accessor: "tiktok.username",
    title: "TikTok Kullanıcı Adı",
    sortable: true,
    render: ({ tiktok }: any) => (
      <a
        href={`https://www.tiktok.com/@${tiktok.username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="text-center items-center">
          <span>{tiktok.username}</span>
        </div>
      </a>
    ),
  },
  { accessor: "country", title: "Ülke", sortable: true },
  {
    accessor: "insta.followers",
    title: "Insta Takipçi",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.insta.followers)}</div>
    },
  },
  {
    accessor: "insta.average_like",
    title: "Insta Ortalama Beğeni",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.insta.average_like)}</div>
    },
  },
  {
    accessor: "insta.engagement_rate",
    title: "Insta Etkileşim Oranı",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.insta?.engagement_rate)}</div>
    },
  },
  {
    accessor: "insta.post_number",
    title: "Insta Gönderi Sayısı",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.insta.post_number)}</div>
    },
  },
  {
    accessor: "tiktok.followers",
    title: "TikTok Takipçi",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.tiktok.followers)}</div>
    },
  },
  {
    accessor: "tiktok.tiktok_average_like",
    title: "TikTok Ortalama Beğeni",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.tiktok.tiktok_average_like)}</div>
    },
  },
  {
    accessor: "tiktok.tiktok_engagement_rate",
    title: "TikTok Etkileşim Oranı",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.tiktok.tiktok_engagement_rate)}</div>
    },
  },
  {
    accessor: "tiktok.hearts",
    title: "TikTok Kalp Sayısı",
    sortable: true,
    render: (record) => {
      return <div>{numFixer(record.tiktok.hearts)}</div>
    },
  },
]

export const generateColumn = (
  accessor: string,
  title: any,
  sortable: boolean = false,
  render?: (record: any) => JSX.Element,
) => ({
  accessor,
  title,
  sortable,
  render,
})
