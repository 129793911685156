import { faEye, faMars, faVenus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Link } from "react-router-dom"
import { numFixer } from "../../../User/getAllUsers/components/Functions"

export const columnConfig = ({ renderStatePayload }) => [
  { accessor: "id", title: "Id", sortable: true },
  {
    accessor: "campaign",
    title: "Kampanya",
    sortable: true,
    render: ({ campaign }) => {
      if (!campaign) {
        return <div className="text-center items-center mr-4">No Campaign</div>
      }
      return (
        <Link to={`/campaigns/find/${campaign}`}>
          <div className="text-center items-center mr-4">
            <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
          </div>
        </Link>
      )
    },
  },
  {
    accessor: "user",
    title: "Kullanıcı",
    sortable: true,
    render: ({ user }) => {
      if (!user) {
        return <div className="text-center items-center mr-4">No User</div>
      }

      return (
        <Link to={`/user/find/${user}`}>
          <div className="text-center items-center mr-4">
            <FontAwesomeIcon icon={faEye} style={{ color: "#005eff" }} />
          </div>
        </Link>
      )
    },
  },
  { accessor: "_id", title: "Başvuru Id" },
  { accessor: "state", title: "State", sortable: true },
  {
    accessor: "state_payload",
    title: "State Payload",
    sortable: true,
    render: renderStatePayload,
  },
  { accessor: "price_user", title: "Kullanıcı Tutarı", sortable: true },
  { accessor: "price_brand", title: "Marka Tutarı", sortable: true },
  {
    accessor: "age",
    title: "Yaş",
    render: ({ age }) => <div>{age > 65 ? "65" : age}</div>,
  },
  {
    accessor: "gender",
    title: "Cinsiyet",
    render: ({ gender }) => (
      <div className="text-center items-center">
        {gender === "male" ? (
          <FontAwesomeIcon icon={faMars} style={{ color: "#005eff" }} />
        ) : gender === "female" ? (
          <FontAwesomeIcon icon={faVenus} style={{ color: "#ff00dd" }} />
        ) : (
          <div>diğer</div>
        )}
      </div>
    ),
  },
  {
    accessor: "verification",
    title: "Onay Durumu",
    sortable: true,
    render: (verification) => (verification ? "True" : "False"),
  },
  { accessor: "job", title: "Meslek" },
  { accessor: "country", title: "Ülke" },
  { accessor: "insta_full_name", title: "IG Tam İsim" },
  {
    accessor: "insta_username",
    title: "Insta Kullanıcı Adı",
    render: ({ insta_username }) => (
      <a
        href={`https://www.instagram.com/${insta_username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="text-center items-center">
          <span>{insta_username}</span>
        </div>
      </a>
    ),
  },
  {
    accessor: "tiktok_username",
    title: "TikTok Kullanıcı Adı",
    render: ({ tiktok_username }) => (
      <a
        href={`https://www.tiktok.com/@${tiktok_username}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="text-center items-center">
          <span>{tiktok_username}</span>
        </div>
      </a>
    ),
  },
  { accessor: "insta_followers", title: "Insta Takipçi" },
  { accessor: "insta_average_like", title: "Insta Ortalama Beğeni" },
  { accessor: "insta_post_number", title: "Insta Gönderi Sayısı" },
  { accessor: "tiktok_followers", title: "TikTok Takipçi" },
  { accessor: "tiktok_average_like", title: "TikTok Ortalama Beğeni" },
  {
    accessor: "tiktok_engagement_rate",
    title: "TikTok Etkileşim Oranı",
    render: ({ tiktok_engagement_rate }) => <div>{numFixer(tiktok_engagement_rate)}</div>,
  },
  { accessor: "tiktok_hearts", title: "TikTok Kalp Sayısı" },
]
